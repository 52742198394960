import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../service/web-api.service';
import { Router } from '@angular/router';
import { UserAuthService } from '../service/user/user-auth.service';
import { ToasterService } from 'angular2-toaster';
import { LanguageTransalte } from '../service/language-translate.service';
declare var $: any;

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.scss']
})
export class TargetComponent implements OnInit {
  initialData = {};
  recommendedDays;
  modeText = 'Practical Mode';
  weightSliderValue = 10;
  recommendedSliderValue = 0.25;
  constructor(public _api: WebApiService,
    public _user: UserAuthService,
    public _toaster: ToasterService,
    public _router: Router,
    public _translate: LanguageTransalte) { }

  ngOnInit() {
    this.getInitialValueOfTargetScreen();
    var env = this;
    $('.input-range').slider({
      formatter: function (value) {
        return value + ' lbs';
      },
      min: 0,
      max: 100,
      value: 10,
    }).change(function (event) {
      env.weightSliderValue = event.target.value;
      $(".input-range3").data('slider').setAttribute('value', event.target.value).refresh();
      env.SetDaysSlider();
    });

    $('.input-range2').slider({
      formatter: function (value) {
        return value + ' Days';
      },
      min: 0,
      max: 100,
      value: 10,
    }).change(function (event) {
      $(".input-range4").data('slider').setAttribute('value', event.target.value).refresh();
      if (env.weightSliderValue / event.target.value >= 0.2 && env.weightSliderValue / event.target.value <= 0.34) {
        env.modeText = 'Practical Mode';
      } else if (env.weightSliderValue / event.target.value < 0.19) {
        env.modeText = 'Easy Peasy Mode';
      } else if (env.weightSliderValue / event.target.value > 0.35) {
        env.modeText = 'Aggressive Mode';
      }
      env.recommendedSliderValue = env.weightSliderValue / event.target.value;
    });
    $('.input-range3').slider({
      formatter: function (value) {
        return value + ' lbs';
      },
      min: 0,
      max: 100,
      value: 10,
    }).change(function (event) {
      env.weightSliderValue = event.target.value;
      $(".input-range").data('slider').setAttribute('value', event.target.value).refresh();
      env.SetDaysSlider();
    });

    $('.input-range4').slider({
      formatter: function (value) {
        return value + ' Days';
      },
      min: 0,
      max: 100,
      value: 10,
    }).change(function (event) {
      $(".input-range2").data('slider').setAttribute('value', event.target.value).refresh();
      if (env.weightSliderValue / event.target.value >= 0.2 && env.weightSliderValue / event.target.value <= 0.34) {
        env.modeText = 'Practical Mode';
      } else if (env.weightSliderValue / event.target.value < 0.19) {
        env.modeText = 'Easy Peasy Mode';
      } else if (env.weightSliderValue / event.target.value > 0.35) {
        env.modeText = 'Aggressive Mode';
      }
      env.recommendedSliderValue = env.weightSliderValue / event.target.value;
    });
  }

  getInitialValueOfTargetScreen() {
    this._api.getInitialValueOfTargetScreen()
      .subscribe(data => {
        this.initialData = data;
        console.log(this.initialData);
        if (!this.initialData['weight']) {
          this.initialData['weight'] = 75
          this.initialData['BMIValue'] = 40
        }
        if (this.initialData['weight'] < this.initialData['BMIValue']) {
          this._toaster.pop('warning', this._translate.getTranslation('ToasterMessages.Hurrey'), this._translate.getTranslation('ToasterMessages.YouAreNotRequiredTo'))
        }
        $('.input-range').data('slider').options.max = parseInt((data['weight'] - data['BMIValue']).toString());
        $('.input-range3').data('slider').options.max = parseInt((data['weight'] - data['BMIValue']).toString());
        this.SetDaysSlider();
      })
  }
  SetDaysSlider() {
    $('.input-range2').data('slider').options.min = parseInt((this.weightSliderValue / 0.4).toFixed());
    $('.input-range2').data('slider').options.max = parseInt((this.weightSliderValue / 0.05).toFixed());
    $(".input-range2").data('slider').setAttribute('value', parseInt((this.weightSliderValue / 0.25).toFixed())).refresh();
    $('.input-range4').data('slider').options.min = parseInt((this.weightSliderValue / 0.4).toFixed());
    $('.input-range4').data('slider').options.max = parseInt((this.weightSliderValue / 0.05).toFixed());
    $(".input-range4").data('slider').setAttribute('value', parseInt((this.weightSliderValue / 0.25).toFixed())).refresh();
    this.recommendedDays = parseInt((this.weightSliderValue / 0.4).toFixed());
    this.recommendedSliderValue = this.weightSliderValue / parseInt((this.weightSliderValue / 0.25).toFixed());
  }
  setUserTarget() {
    if (this.initialData['weight'] < this.initialData['BMIValue']) {
      this._toaster.pop('warning', this._translate.getTranslation('ToasterMessages.Hurrey'), this._translate.getTranslation('ToasterMessages.YouAreNotRequiredTo'))
      return;
    }
    this._api.setUserTarget({ targetWeight: this.initialData['weight'] - Number(this.weightSliderValue), daysToAttainWeight: Number($('.input-range2')[0].value) })
      .subscribe(data => {
        this._user.user.isTargetSet = true;
        this._user.setUser(this._user.user);
        this._router.navigate(['/subscription-plan']);
      }, err => {
        if (err['status'] === 200) {
          this._user.user.isTargetSet = true;
          this._user.setUser(this._user.user);
          this._router.navigate(['/subscription-plan']);
        } else {
          console.log(err)
        }
      })
  }
}
