import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { WebApiService } from 'src/app/service/web-api.service';
import { ToasterService } from 'angular2-toaster';
import { UserAuthService } from 'src/app/service/user/user-auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { LanguageTransalte } from '../service/language-translate.service';
declare var $: any;

@Component({
  selector: 'app-gender-check',
  templateUrl: './gender-check.component.html',
  styleUrls: ['./gender-check.component.scss']
})
export class GenderCheckComponent implements OnInit {
  @ViewChild('number_type') number_type: ElementRef;
  user_gender;
  weight;
  height;
  user_dob;
  numberOfMealsPerDay;
  firstMealTime;
  common;
  modalType = 'height';
  weight_unit = 'LBS';

  constructor(public _api: WebApiService,
    public _user: UserAuthService,
    public _router: Router,
    public _toaster: ToasterService,
    public _translate: LanguageTransalte) { }

  ngOnInit() {
    this.getUserDetails();
    $('.datepicker').bootstrapMaterialDatePicker({
      format: "DD - MM - YYYY",
      time: false,
      minDate: "01-01-1900",
      maxDate: new Date()
    }).change(event => {
      this.user_dob = event.target.value;
    })
    $('.timepicker').bootstrapMaterialDatePicker({
      format: "hh : mm A",
      shortTime: true,
      date: false,
    }).change(event => {
      this.firstMealTime = event.target.value;
    })
  }
  openCalendarByClickOnIcon() {
    $('.datepicker').focus().focus();
  }
  openTimePickerByClickOnIcon() {
    $('.timepicker').focus().focus();
  }

  timeValidation(value) {
    let time = value;
    time = time.split(':')
    if (parseInt(time[0]) == 0) {
      return "12 : " + (parseInt(time[1]) < 10 ? "0"+parseInt(time[1]) : parseInt(time[1])) + " AM";
    } else if (parseInt(time[0]) >= 1 && parseInt(time[0]) < 10) {
      return "0" + parseInt(time[0]) + " : " + (parseInt(time[1]) < 10 ? "0"+parseInt(time[1]) : parseInt(time[1])) + " AM";
    } else if (parseInt(time[0]) == 11) {
      return time[0] + " : " + (parseInt(time[1]) < 10 ? "0"+parseInt(time[1]) : parseInt(time[1])) + " AM";
    } else if (parseInt(time[0]) == 12) {
      return time[0] + " : " + (parseInt(time[1]) < 10 ? "0"+parseInt(time[1]) : parseInt(time[1])) + " PM";
    } else if (parseInt(time[0]) > 12) {
      return (parseInt(time[0]) - 12 < 10 ? "0" + (parseInt(time[0]) - 12) : parseInt(time[0])) + " : " + (parseInt(time[1]) < 10 ? "0" + parseInt(time[1]) : parseInt(time[1])) + " PM";
    }
  }

  weightInputFocus() {
    setTimeout(() => {
      this.number_type.nativeElement.focus();
    }, 0)
  }

  getUserDetails() {
    this._api.getUserDetails(``)
      .subscribe(data => {
        console.log(data);
        if (data && data['gender']) {
          // this._router.navigate(['/started'])
          this.user_gender = data['gender'];
          this.user_dob = moment(data['dob'] * 1000).format('DD - MM - YYYY');
          this.height = data['height'];
          this.weight = data['weight'];
          this.numberOfMealsPerDay = data['numberOfMealPerDay'];
          this.firstMealTime = this.timeValidation(data['firstMealTime']);
        }
      }, err => {
        console.log(err);
      })
  }
  UpdateUserProfile() {
    if (!this.user_gender) {
      this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.SelectGenderError'));
      return;
    }
    if (!this.user_dob) {
      this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.DateOfBirthError'));
      return;
    }
    if (!this.height || this.height == 0) {
      this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.HeightAddError'));
      return;
    }
    if (!this.weight || this.weight == 0) {
      this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.WeightAddError'));
      return;
    }
    if (!this.numberOfMealsPerDay) {
      this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.EnterMealsPerDay'));
      return;
    }
    if (!this.firstMealTime) {
      this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.FirstMealTime'));
      return;
    }
    this.weight = parseFloat(this.weight).toFixed(2);
    let mealTime = moment(this.firstMealTime, "hh : mm A").unix() * 1000;
    // console.log(this.user_gender, this.user_dob, this.height, this.weight, this.numberOfMealsPerDay, this.firstMealTime);
    this._api.UpdateUserProfile({
      gender: this.user_gender,
      dob: moment(this.user_dob, "DD - MM - YYYY").unix(),
      height: this.height,
      numberOfMealsPerDay: this.numberOfMealsPerDay,
      firstMealTime: new Date(mealTime).getHours() + ":" + new Date(mealTime).getMinutes(),
      firstMealTimeUpdatedAt: moment().startOf('day').unix()
    })
      .subscribe(data => {
        if (data['code'] === 200) {
          this._api.setBodyWeight({ "weight": this.weight })
            .subscribe(data => {
              if (data['code'] === 200) {
                this._api.getInitialValueOfTargetScreen()
                  .subscribe(data => {
                    console.log(data);
                    if (data && data['weight'] > data['BMIValue']) {
                      this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.ProfileCreatedSuccessfully'));
                      this._user.user.gender = this.user_gender;
                      this._user.user['isProfileUpdateRequired'] = false;
                      this._user.setUser(this._user.user)
                      this._router.navigate(['/started'])
                    } else {
                      this._toaster.pop('warning', this._translate.getTranslation('ToasterMessages.Hurrey'), this._translate.getTranslation('ToasterMessages.YouAreNotRequiredTo'));
                    }
                  }, err => {
                    console.log(err);
                  })

              }
            }, err => {
              console.log(err);
            })
        }
      }, err => {
        console.log(err);
      })
  }
  stopToEnterLargeNumber(common) {
    console.log(common);
    let regex = /^-?\d+[.]?\d{0,2}/gm;
    if (common.toString().match(regex)) {
      this.common = common.toString().match(regex)[0];
    } else {
      this.common = '';
    }
  }

  minusCommon() {
    if (this.common >= 1) {
      if (this.common.toString().indexOf('.') != -1) {
        if (this.common.toString().split('.')[1].length == 1) {
          this.common = this.common - 1;
          this.common = this.common.toFixed(1)
        } else {
          this.common = this.common - 1;
          this.common = this.common.toFixed(2)
        }
      } else {
        this.common = this.common - 1;
      }
    } else if (parseInt(this.common) == 0) {
      // this.weightInputFocus()
    } else {
      this.weightInputFocus();
    }
    this.weightInputFocus();
  }
  plusCommon() {
    this.common = Number(this.common) + 1;
    this.weightInputFocus();
  }
  setWeightOrHeight() {
    if (this.modalType == 'height') {
      this.height = this.common;
    } else {
      this.weight = this.weight_unit == 'KGS' ? (this.common * 2.2).toFixed(2) : this.common;
      this.weight = this.weight.split('.')[1] == 0 ? parseInt(this.weight.split('.')[0]) : this.weight;
      this.weight_unit = 'LBS';
      // this.weight = this.common;
    }
    this.common = 0;
  }

}