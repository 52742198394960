import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { WebApiService } from '../service/web-api.service';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { LanguageTransalte } from '../service/language-translate.service';
@Component({
  selector: 'app-our-goal',
  templateUrl: './our-goal.component.html',
  styleUrls: ['./our-goal.component.scss']
})
export class OurGoalComponent implements OnInit {
  goalList;
  selectedGoalList = [];
  constructor(public _api: WebApiService,
    public _router: Router,
    public _toaster: ToasterService,
    public _translate: LanguageTransalte) { }
  ngOnInit() {
    this.getAdditionalGoal();
    setTimeout(_=> {
      this.selectedGoalList.push({
        "id": 1,
        "additionalGoalId": 0,
        "title": this._translate.getTranslation('Goals.BalanceYourHormons'),
        "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "iconSVG": null
      });
      this.selectedGoalList[0]['title'] = this.selectedGoalList[0]['title'] ? this.selectedGoalList[0]['title'] : "Balance Your Hormones";
    },500)
    
  }
  // onDrop(event: CdkDragDrop<string[]>) {
  //   if (event.previousContainer === event.container) {
  //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  //   } else {
  //     if (this.selectedGoalList.length >= 2 && !(event.previousIndex == 1 && event.currentIndex == 1)) {
  //       this.goalList = this.selectedGoalList.splice(1, 1);
  //     }
  //     transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
  //   }
  //   this.goalList = this.goalList.sort((x, y) => { return x.id - y.id });
  //   this.selectedGoalList = this.selectedGoalList.sort((x, y) => { return x.id - y.id });
  // }
  onClickDragDrop(id, text, index) {
    if (this.selectedGoalList.length == 1 && text == 'anotherGoal') {
      this.selectedGoalList.push(this.goalList.splice(index, 1)[0]);
    } else if (this.selectedGoalList.length > 1 && text == 'anotherGoal') {
      this.goalList = this.selectedGoalList.splice(1, 1, this.goalList[0]);
    } else if (this.selectedGoalList.length > 1 && text == 'selectedGoal') {
      this.goalList.push(this.selectedGoalList.splice(1, 1)[0]);
      this.goalList = this.goalList.sort((x, y) => { return x.id - y.id });
    }
  }

  getAdditionalGoal() {
    this._api.getAdditionalGoal()
      .subscribe(data => {
        this.goalList = data;
        this.goalList.forEach((goal, index) => {
          goal['id'] = index + 2;
        })
        console.log(this.goalList);
      })
  }

  setUserGoal() {
    if (this.selectedGoalList.length > 1) {
      this._api.setUserGoal(this.selectedGoalList[1]['additionalGoalId'])
        .subscribe(data => {
          this._router.navigate(['/target']);
        }, err => {
          if (err['status'] == 200) {
            this._router.navigate(['/target']);
          } else {
            console.log(err);
          }
        })
    } else {
      this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.PleaseSelectAdditionGoal'));
    }
  }
}