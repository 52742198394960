import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidatorService } from 'src/app/service/custom-validator.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WebApiService } from 'src/app/service/web-api.service';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
declare var Stripe: any;
import { Router, ActivatedRoute } from '@angular/router';
import { UserAuthService } from 'src/app/service/user/user-auth.service';
declare var $: any;

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  constructor(public fb: FormBuilder,
    public _customValidators: CustomValidatorService,
    public _http: HttpClient,
    public _api: WebApiService,
    public _toaster: ToasterService,
    public _router: Router,
    public _user: UserAuthService,
    public ngZone: NgZone,
    public _routes: ActivatedRoute) {
  }

  ngOnInit() {
    if(!(this._user && this._user.user && this._user.user.userId)) {
      this._toaster.pop('error', 'Error', 'Please login your account');
      localStorage.setItem('unsubscribe', JSON.stringify(parseInt(this._routes.snapshot.params.userId)));
      this._router.navigate(['/login']);
    } else if(this._user && this._user.user && (this._user.user.userId != parseInt(this._routes.snapshot.params.userId))) {
      localStorage.setItem('unsubscribe', JSON.stringify(parseInt(this._routes.snapshot.params.userId)));
      this._user.resetUser();
      this._toaster.pop('error', 'Error', 'Please login with your account');
      this._router.navigate(['/login']);
    }
  }

  unSubscribeNewsLetter() {
    this._api.unSubscribeNewsLetter()
    .subscribe(data => {
      console.log("Data", data);
      if(data && data['msg']) {
        this._toaster.pop('success', 'Success', 'Unsubscribed Successful')
        this._router.navigate(['/dashboard']);
        localStorage.removeItem('unsubscribe');
      }
    }, err => {
      console.log("Error", err)
      if(err['status'] != 200) {
        this._toaster.pop('error', 'Error', err['error']['msg'] || 'Something went wrong!');
      }
    })
  }

  Goto(link) {
    localStorage.removeItem('unsubscribe');
    this._router.navigate([link]);
  }
}
