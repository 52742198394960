import { Component, Injector, OnInit } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { LoaderService } from '../loader/loader.service';
import { MatDialog } from '@angular/material';
import { UserAuthService } from '../user/user-auth.service';



@Component({
    selector: 'app-http-interceptor',
    template: ''
})
export class HttpInterCeptorComponent implements HttpInterceptor, OnInit {
    http_count: any;

    constructor(private router: Router,
        private _toaster: ToasterService,
        public dialog: MatDialog,
        public _user: UserAuthService,
        private _loader: LoaderService) {
        this.http_count = 0;
    }

    ngOnInit() {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const env = this;
        // console.log(env.http_count, 'loader started');
        if (!(req.url.indexOf('getFoodSearch') !== -1 || req.url.indexOf('saveFavoriteFoodItemByName') !== -1 || req.url.indexOf('getAllActivitiesList') !== -1)) {
            env.http_count += 1;
            env._loader.show();
        }
        // console.log(env.http_count, '1')

        const clonedRequest = req.clone({
            url: env.fixUrl(req.url)
        });
        return next.handle(clonedRequest)
            .do(event => {
                // console.log(event);
                if (event && event['body'] && event['body']['status']) {
                    if (event['body']['status'] === 401 || event['body']['status'] === 409) {
                        env._toaster.pop('error', 'Error', 'User session is expired');
                        this._user.resetUser();
                        this.router.navigate(['/']);
                    }
                }
                if (event instanceof HttpResponse) {
                    env.http_count -= 1;
                    if (env.http_count <= 0) {
                        env._loader.hide();
                    }
                }
            })
            .catch((res) => {
                // console.log(env.http_count, 'loader in catch');
                // console.log("response---", res, res['status'])
                if (res['status'] === 409 && localStorage.getItem('user')) {
                    env._toaster.pop('error', 'Error', 'User session is expired');
                    this._user.resetUser();
                    this.router.navigate(['/']);
                }
                env.http_count -= 1; 0
                if (env.http_count <= 0) {
                    env._loader.hide();
                }
                return Observable.throw(res);
            });
    }

    private fixUrl(url: string) {
        return url;

    }
}