
import { NgModule } from '@angular/core';
import { HttpService } from './http.service';
import { WebApiService } from './web-api.service';
import { SocialLoginModule, AuthServiceConfig, FacebookLoginProvider, GoogleLoginProvider } from 'angular-6-social-login';
import { SocialLoginService } from './user/social-login-service';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../footer/footer.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FileValidationDirective } from '../directive/file-validation.directive';
import { PointMarkerComponent } from './point-marker/point-marker.component';
import { ImagesService } from './images.service';
import { PlusIconComponent } from '../plus-icon/plus-icon.component';
import { OwlModule } from 'ngx-owl-carousel';
import { NotFoundComponent } from '../not-found/not-found.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { LanguageSelectorComponent } from '../language-selector/language-selector.component';
import { FormsModule } from '@angular/forms';
import { ImgCompressorDirective } from '../directive/food-image-compressor';
import { Safe } from './safe.pipe';
import {DesktopHeaderComponent} from '../desktop-header/desktop-header.component';
import {DesktopSidebarComponent} from '../desktop-sidebar/desktop-sidebar.component';

@NgModule({
    declarations: [FooterComponent, FileValidationDirective, ImgCompressorDirective, PointMarkerComponent, PlusIconComponent, NotFoundComponent, LanguageSelectorComponent, Safe, DesktopHeaderComponent, DesktopSidebarComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        OwlModule,
        OwlDateTimeModule,
        SocialLoginModule,
        OwlNativeDateTimeModule
    ],
    providers: [
        HttpService,
        WebApiService,
        SocialLoginService,
        ImagesService,
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        }
    ],
    exports: [CommonModule, FormsModule, FooterComponent, NotFoundComponent, TranslateModule, FileValidationDirective, ImgCompressorDirective, PointMarkerComponent, PlusIconComponent, OwlModule, OwlDateTimeModule, OwlNativeDateTimeModule, LanguageSelectorComponent,Safe, DesktopHeaderComponent, DesktopSidebarComponent]
})
export class SharedModule { }


// Configs for social login
export function getAuthServiceConfigs() {
    let config = new AuthServiceConfig(
        [
            {
                id: FacebookLoginProvider.PROVIDER_ID,
                provider: new FacebookLoginProvider("428026937943179")
            },
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider("6537271186-n5ksf9a7ikg18ku4v4s53njloj1rdk7e.apps.googleusercontent.com")
            }
        ]
    );
    return config;
}
