import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { ToasterModule } from 'angular2-toaster';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterCeptorComponent } from './service/http-interceptor/http.interceptor';
import { SpinnerComponent } from './service/spinner/spinner.component';
import { LoaderService } from './service/loader/loader.service';
import { RouterModule } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from './service/shared.module';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CustomValidatorService } from './service/custom-validator.service';
import { QuestionareComponent } from './questionare/questionare.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SplashComponent } from './splash/splash.component';
import { StartedScreenComponent } from './started-screen/started-screen.component';
import { UserAuthService, IsWebLoggedIn, CanLogIn } from './service/user/user-auth.service';
import { IntroductryPageComponent } from './introductry-page/introductry-page.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { IsPlanCreated, CanGoToQuestionnaire, CanGotoProfile, CanGoForPayment, IsSubscriptionActive, CanGoToOnboarding, IstargetActivated } from './service/routing-gaurds.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { OwlModule } from 'ngx-owl-carousel';
import { GenderCheckComponent } from './gender-check/gender-check.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { BodyTypeComponent } from './body-type/body-type.component';
import { OurGoalComponent } from './our-goal/our-goal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlickityModule } from 'ngx-flickity';
import { TargetComponent } from './target/target.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { PaymentComponent } from './payment/payment.component';
import { ServeyComponent } from './servey/servey.component';
import { DesktopHeaderComponent } from './desktop-header/desktop-header.component';
import { DesktopSidebarComponent } from './desktop-sidebar/desktop-sidebar.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LanguageTransalte } from './service/language-translate.service';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    SpinnerComponent,
    HttpInterCeptorComponent,
    QuestionareComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SplashComponent,
    StartedScreenComponent,
    IntroductryPageComponent,
    GenderCheckComponent,
    OnboardingComponent,
    BodyTypeComponent,
    OurGoalComponent,
    TargetComponent,
    SubscriptionPlanComponent,
    PaymentComponent,
    ServeyComponent,
    PrivacyPolicyComponent,
    UnsubscribeComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    ToasterModule.forRoot(),
    NgbModule.forRoot(),
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'top'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    Ng2ImgMaxModule,
    AngularFontAwesomeModule,
    OwlModule,
    DragDropModule,
    FlickityModule
  ],
  exports: [],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpInterCeptorComponent,
    multi: true,
  },
    LoaderService,
    UserAuthService,
    IsWebLoggedIn,
    IsPlanCreated,
    CanGoToQuestionnaire,
    CanGotoProfile,
    CanLogIn,
    CanGoForPayment,
    IsSubscriptionActive,
    IstargetActivated,

    CanGoToOnboarding,
    CustomValidatorService,
    LanguageTransalte],
  bootstrap: [AppComponent]
})
export class AppModule { }
