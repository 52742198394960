import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
@Injectable()
export class CanGoToOnboarding implements CanActivate {
    constructor(public _router: Router,
        public _toaster: ToasterService) { }
    canActivate() {
        if (localStorage.getItem('lang')) {
            if (localStorage.getItem('appLaunched')) {
                let user = JSON.parse(localStorage.getItem('user'));
                if (user) {
                    if (user && (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired))) {
                        if (!user.gender) {
                            this._router.navigate(['/started']);
                            return false;
                        } else {
                            this._router.navigate(['/questionnaire']);
                            return false;
                        }
                    } else if (user && (user.isPlanCreated && !user.isAnswersRequired && !user.isProfileUpdateRequired)) {
                        this._router.navigate(['/dashboard']);
                        return false;
                    } else {
                        this._router.navigate(['/questionnaire']);
                        return false;
                    }
                } else {
                    this._router.navigate(['/login']);
                    return false;
                }
            } else {
                return true;
            }
        } else {
            this._router.navigate(['/language']);
            return false;
        }
    }
}

@Injectable()
export class CanGoToQuestionnaire implements CanActivate {
    constructor(public _router: Router,
        public _toaster: ToasterService) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (!user.gender) {
                this._router.navigate(['/profile-info']);
                return false;
            } else if(user.gender && user.isProfileUpdateRequired) {
                this._router.navigate(['/profile-info']);
                return false;
            } else if (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired)) {
                return true;
            } else if (user.isTargetSet && user.isSubscriptionActive) {
                this._router.navigate(['/dashboard']);
                return false;
            } else if (user.isTargetSet && !user.isSubscriptionActive) {
                this._router.navigate(['/subscription-plan']);
                return false;
            } else if (!user.isTargetSet && user.isSubscriptionActive) {
                this._router.navigate(['/OurGoal']);
                return false;
            }
        } else {
            this._toaster.pop('error', 'Error', 'Please login');
            this._router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable()
export class IsSubscriptionActive implements CanActivate {
    constructor(public _router: Router,
        public _toaster: ToasterService) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (!user.gender) {
                this._toaster.pop('error', 'Error', 'Please complete your profile');
                this._router.navigate(['/profile-info']);
                return false;
            } else if(user.gender && user.isProfileUpdateRequired) {
                this._router.navigate(['/profile-info']);
                return false;
            } else if (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired)) {
                this._toaster.pop('error', 'Error', 'Please fill the questionnaire');
                this._router.navigate(['/questionnaire']);
                return false;
            } else if (!user.isTargetSet) {
                this._toaster.pop('error', 'Error', 'Please set your target');
                this._router.navigate(['/OurGoal']);
                return false;
            // } else if (!user.isSubscriptionActive) {
            //     this._toaster.pop('error', 'Error', 'Please start your subscription');
            //     this._router.navigate(['/subscription-plan']);
            //     return false;
            } else {
                return true;
            }
        } else {
            this._toaster.pop('error', 'Error', 'Please login');
            this._router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable()
export class CanGoForPayment implements CanActivate {
    constructor(public _router: Router,
        public _toaster: ToasterService) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (!user.gender) {
                this._toaster.pop('error', 'Error', 'Please complete your profile');
                this._router.navigate(['/profile-info']);
                return false;
            } else if(user.gender && user.isProfileUpdateRequired) {
                this._router.navigate(['/profile-info']);
                return false;
            } else if (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired)) {
                this._toaster.pop('error', 'Error', 'Please fill the questionnaire');
                this._router.navigate(['/questionnaire']);
                return false;
            } else if (!user.isTargetSet) {
                this._toaster.pop('error', 'Error', 'Please set your target');
                this._router.navigate(['/OurGoal']);
                return false;
            } else {
                return true;
            }
        } else {
            this._toaster.pop('error', 'Error', 'Please login');
            this._router.navigate(['/login']);
            return false;
        }
    }
}
@Injectable()
export class IstargetActivated implements CanActivate {
    constructor(public _router: Router,
        public _toaster: ToasterService) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (!user.gender) {
                this._toaster.pop('error', 'Error', 'Please complete your profile');
                this._router.navigate(['/profile-info']);
                return false;
            } else if(user.gender && user.isProfileUpdateRequired) {
                this._router.navigate(['/profile-info']);
                return false;
            } else if (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired)) {
                this._toaster.pop('error', 'Error', 'Please fill the questionnaire');
                this._router.navigate(['/questionnaire']);
                return false;
            } else if (!user.isTargetSet) {
                console.log("helwTarget check123121")
                return true;
            } else if (!user.isSubscriptionActive) {
                this._toaster.pop('error', 'Error', 'Please start your subscription');
                this._router.navigate(['/subscription-plan']);
                return false;
            } else {
                this._router.navigate(['/dashboard']);
                return false;
            }
        } else {
            this._toaster.pop('error', 'Error', 'Please login');
            this._router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable()
export class CanGotoProfile implements CanActivate {
    // not used anywhere in the new build
    constructor(public _router: Router,
        public _toaster: ToasterService) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if (user && (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired))) {
                if (!user.hasUserFilledAnswersAtleastOnce) {
                    this._toaster.pop('error', 'Error', 'Please fill the Questionnaire first');
                }
                this._router.navigate(['/questionnaire']);
                return false;
            } else {
                return true;
            }
        } else {
            this._toaster.pop('error', 'Error', 'Please login');
            this._router.navigate(['/login']);
            return false;
        }
    }
}

@Injectable()
export class IsPlanCreated implements CanActivate {
    // not used anywhere in the new build
    constructor(public _router: Router,
        public _toaster: ToasterService) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            if(user.gender && user.isProfileUpdateRequired) {
                this._router.navigate(['/profile-info']);
                return false;
            }
            if (user && (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired))) {
                if (!user.hasUserFilledAnswersAtleastOnce) {
                    this._toaster.pop('error', 'Error', 'Please fill the Questionnaire first');
                }
                this._router.navigate(['/questionnaire']);
                return false;
            } else {
                if (user && !user.isPlanCreated || user.isProfileUpdateRequired) {
                    if (!user.isPlanCreated) {
                        this._toaster.pop('error', 'Error', 'Please complete your profile');
                    } else if (user.isProfileUpdateRequired) {
                        this._toaster.pop('error', 'Error', 'Please update your current profile details');
                    }
                    this._router.navigate(['/profile']);
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            this._toaster.pop('error', 'Error', 'Please login');
            this._router.navigate(['/login']);
            return false;
        }
    }
}