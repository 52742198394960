import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { StartedScreenComponent } from './started-screen/started-screen.component';
import { QuestionareComponent } from './questionare/questionare.component';
import { CanLogIn, IsWebLoggedIn } from './service/user/user-auth.service';
import { CanGoToQuestionnaire, IsSubscriptionActive, CanGoToOnboarding, CanGoForPayment, IstargetActivated } from './service/routing-gaurds.service';
import { GenderCheckComponent } from './gender-check/gender-check.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { BodyTypeComponent } from './body-type/body-type.component';
import { OurGoalComponent } from './our-goal/our-goal.component';
import { TargetComponent } from './target/target.component';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { PaymentComponent } from './payment/payment.component';
import { ServeyComponent } from './servey/servey.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
export const AppRoutes: Routes =
  [
    {
      path: '',
      redirectTo: 'onboarding',
      pathMatch: 'full'
    }, {
      path: 'login',
      component: LoginComponent,
      canActivate: [CanLogIn],
      data: { title: 'Sign In' },
    }, {
      path: 'language',
      component: LanguageSelectorComponent,
      canActivate: [CanLogIn],
      data: { title: 'Select Language' },
    }, {
      path: 'signup',
      component: SignupComponent,
      canActivate: [CanLogIn],
      data: { title: 'SignUp' },
    }, {
      path: 'forgotPassword',
      component: ForgotPasswordComponent,
      canActivate: [CanLogIn],
      data: { title: 'Forgot Password' },
    }, {
      path: 'verifyResetPasswordToken',
      component: ResetPasswordComponent,
     canActivate: [CanLogIn],
      data: { title: 'Verify Token' }
    }, {
      path: 'resetPassword',
      component: ResetPasswordComponent,
      canActivate: [CanLogIn],
      data: { title: 'Reset Password' }
    }, {
      path: 'started',
      component: StartedScreenComponent,
      canActivate: [CanGoToQuestionnaire],
      data: { title: 'Get Started' }
    }, {
      path: 'questionnaire',
      component: QuestionareComponent,
      canActivate: [CanGoToQuestionnaire],
      data: { title: 'Questionnaire' }
      // }, {
      //   path: 'introductory',
      //   component: IntroductryPageComponent,
      //   canActivate: [CanGoToQuestionnaire],
      //   data: { title: 'Introductory' }
    }, {
      path: 'onboarding',
      component: OnboardingComponent,
      canActivate: [CanGoToOnboarding],
      data: { title: 'Onboarding' }
    }, {
      path: 'profile',
      loadChildren: './profile/profile.module#ProfileModule',
      canActivate: [IsSubscriptionActive],
      // }, {
      //   path: 'plan3',
      //   component: Plan3Component,
      //   data: { title: 'Payment Introduction' }
    }, {
      path: 'dashboard',
      canActivate: [IsSubscriptionActive],
      loadChildren: './dashboard/dashboard.module#DashboardModule',
    }, {
      path: 'nutrition',
      canActivate: [IsSubscriptionActive],
      loadChildren: './nutrition/nutrition.module#NutritionModule',
    }, {
      path: 'plan',
      canActivate: [IsSubscriptionActive],
      loadChildren: './plan/plan.module#PlanModule',
      // }, {
      //   path: 'lifestyle',
      //   loadChildren: './lifestyle/lifestyle.module#LifestyleModule',
    }, {
      path: 'exercise',
      canActivate: [IsSubscriptionActive],
      loadChildren: './exercise/exercise.module#ExerciseModule',
    }, {
      path: 'sleep',
      canActivate: [IsSubscriptionActive],
      loadChildren: './sleep/sleep.module#SleepModule',
    }, {
      path: 'meditation',
      canActivate: [IsSubscriptionActive],
      loadChildren: './meditation/meditation.module#MeditationModule',
    }, {
      path: 'black-friday',
      loadChildren: './black-friday/black-friday.module#BlackFridayModule',
    }, {
      path: 'admin',
      // canActivate: [IsSubscriptionActive],
      loadChildren: './admin-panel/admin-panel.module#AdminPanelModule',
    }, {
      path: 'profile-info',
      component: GenderCheckComponent,
      canActivate: [IsWebLoggedIn],
      data: { title: 'Gender' }
    }, {
      path: 'subscription-plan',
      component: SubscriptionPlanComponent,
      canActivate: [CanGoForPayment],
      data: { title: 'Subscription plan' }
    }, {
      path: 'bodytype',
      canActivate: [IstargetActivated],
      component: BodyTypeComponent,
      data: { title: 'Body Type' }
      // }, {
      //   path: 'fsl-journey',
      //   component: FslJourneyComponent,
      //   data: { title: 'Fantastic life Journey' }
    }, {
      path: 'OurGoal',
      component: OurGoalComponent,
      canActivate: [IstargetActivated],
      data: { title: 'Our Goal' }
    }, {
      path: 'target',
      component: TargetComponent,
      canActivate: [IstargetActivated],
      data: { title: 'User Target' },
    }, {
      path: 'payment',
      component: PaymentComponent,
      canActivate: [IstargetActivated],
      data: { title: 'Payment' }
    }, {
      path: 'find-my-dominant-hormone',
      component: ServeyComponent,
      data: { title: 'Find My Dominant Hormone' }
    }, {
      path: 'privacy-policy',
      component: PrivacyPolicyComponent,
      data: { title: 'Privacy & Policy' }
    }, {
      path: 'unsubscribe/:userId',
      component: UnsubscribeComponent,
      data: { title: 'UnSubscribe' }
    }, {
      path: '**',
      component: NotFoundComponent,
      data: { title: 'Not Found' },
    }
  ];
