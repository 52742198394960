import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidatorService } from '../service/custom-validator.service';
import { ToasterService } from 'angular2-toaster';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { WebApiService } from '../service/web-api.service';
import * as moment from 'moment';
import { LoaderService } from '../service/loader/loader.service';
import { ImagesService } from '../service/images.service';
import * as _ from 'underscore';
import { UserAuthService } from '../service/user/user-auth.service';

@Directive({
  selector: '[appFileValidation]'
})
export class FileValidationDirective {
  @Input('appFileValidation') appFileValidation: any;
  @Input() maxSizeImage: any;
  @Input() fileType: any;
  @Input() fileUpload: any;
  @Output() imageTypeError: EventEmitter<any> = new EventEmitter();
  @Output() imageTypeResError: EventEmitter<any> = new EventEmitter();
  @Output() imageData: EventEmitter<any> = new EventEmitter();
  target_image_data: any;
  image_error;
  upload_image;
  images_to_upload = 0;;
  images_iterated = 0;
  constructor(public fb: FormBuilder,
    public fileService: CustomValidatorService,
    public _toaster: ToasterService,
    private ng2ImgMax: Ng2ImgMaxService,
    public _api: WebApiService,
    public _imageService: ImagesService,
    public _user: UserAuthService,
    public _loader: LoaderService) {
  }

  @HostListener('change', ['$event']) onchange(event) {
    this.image_error = false;
    this.imageTypeError.emit(false);
    var allowed_extensions_img = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (event.srcElement.attributes.name.value === 'food') {
      if (event.target.files.length + this._imageService.nutrition_images.length > 30) {
        this._toaster.pop('error', 'Error', 'You can upload at most 30 images for the food activity')
        return;
      }
      if (event.target.files.length > 10) {
        this._toaster.pop('warning', 'Warning', 'You can upload 10 images at one time')
        return;
      }
    }
    if (event.target.files) {
      this.images_to_upload = event.target.files.length
      this.images_iterated = 0
      _.each(event.target.files, (val, index) => {
        var file = event.target.files[index];
        if (file && file.name) {
          var file_name = file.name;
          var file_extension = file_name.split('.').pop();
        }
        let reader = new FileReader();
        //console.log(" this.fileUpload--", this.fileType, file)
        if (file && this.fileType === 'image') {
          if (this.isInArray(allowed_extensions_img, file_extension)) {
            this.image_error = false;
            this.imageTypeError.emit(false);
            if (event.srcElement.attributes.name.value !== 'food') {
              this._loader.show();
            }
            console.log(file)
            if (file.size < 1024 * 1024) {
              this.ng2ImgMax.compressImage(file, 0.100, false, true).subscribe(
                result => {
                  //console.log("result---", result)
                  this.SaveFile(result, reader, file, event)
                },
                error => {
                  console.log("errrrrr1", error)
                  // this._toaster.pop('error', 'Error', error['reason']);
                  this.ng2ImgMax.compressImage(file, 0.250, false, true).subscribe(
                    result => {
                      //console.log("result---", result)
                      this.SaveFile(result, reader, file, event)
                    },
                    error => {
                      console.log("errrrrr1", error)
                      // this._toaster.pop('error', 'Error', error['reason']);
                      this.ng2ImgMax.compressImage(file, 0.500, false, true).subscribe(
                        result => {
                          //console.log("result---", result)
                          this.SaveFile(result, reader, file, event)
                        },
                        error => {
                          console.log("errrrrr3", error)
                          this.SaveFile(error.compressedFile, reader, file, event)
                        }
                      );
                    }
                  );
                }
              );
            } else if (file.size < 2 * 1024 * 1024) {
              this.ng2ImgMax.compressImage(file, 0.250, false, true).subscribe(
                result => {
                  //console.log("result---", result)
                  this.SaveFile(result, reader, file, event)
                },
                error => {
                  console.log("errrrrr1", error)
                  // this._toaster.pop('error', 'Error', error['reason']);
                  this.ng2ImgMax.compressImage(file, 0.400, false, true).subscribe(
                    result => {
                      //console.log("result---", result)
                      this.SaveFile(result, reader, file, event)
                    },
                    error => {
                      console.log("errrrrr1", error)
                      // this._toaster.pop('error', 'Error', error['reason']);
                      this.ng2ImgMax.compressImage(file, 0.650, false, true).subscribe(
                        result => {
                          //console.log("result---", result)
                          this.SaveFile(result, reader, file, event)
                        },
                        error => {
                          console.log("errrrrr3", error)
                          this.SaveFile(error.compressedFile, reader, file, event)
                        }
                      );
                    }
                  );
                }
              );
            } else if (file.size < 3 * 1024 * 1024) {
              this.ng2ImgMax.compressImage(file, 0.400, false, true).subscribe(
                result => {
                  //console.log("result---", result)
                  this.SaveFile(result, reader, file, event)
                },
                error => {
                  console.log("errrrrr1", error)
                  // this._toaster.pop('error', 'Error', error['reason']);
                  this.ng2ImgMax.compressImage(file, 0.650, false, true).subscribe(
                    result => {
                      //console.log("result---", result)
                      this.SaveFile(result, reader, file, event)
                    },
                    error => {
                      console.log("errrrrr1", error)
                      // this._toaster.pop('error', 'Error', error['reason']);
                      this.ng2ImgMax.compressImage(file, 0.800, false, true).subscribe(
                        result => {
                          //console.log("result---", result)
                          this.SaveFile(result, reader, file, event)
                        },
                        error => {
                          console.log("errrrrr3", error)
                          this.SaveFile(error.compressedFile, reader, file, event)
                        }
                      );
                    }
                  );
                }
              );
            } else if (file.size < 4 * 1024 * 1024) {
              this.ng2ImgMax.compressImage(file, 0.550, false, true).subscribe(
                result => {
                  //console.log("result---", result)
                  this.SaveFile(result, reader, file, event)
                },
                error => {
                  console.log("errrrrr1", error)
                  // this._toaster.pop('error', 'Error', error['reason']);
                  this.ng2ImgMax.compressImage(file, 0.700, false, true).subscribe(
                    result => {
                      //console.log("result---", result)
                      this.SaveFile(result, reader, file, event)
                    },
                    error => {
                      console.log("errrrrr1", error)
                      // this._toaster.pop('error', 'Error', error['reason']);
                      this.ng2ImgMax.compressImage(file, 0.950, false, true).subscribe(
                        result => {
                          //console.log("result---", result)
                          this.SaveFile(result, reader, file, event)
                        },
                        error => {
                          console.log("errrrrr3", error)
                          this.SaveFile(error.compressedFile, reader, file, event)
                        }
                      );
                    }
                  );
                }
              );
            } else {
              this.ng2ImgMax.compressImage(file, 0.700, false, true).subscribe(
                result => {
                  //console.log("result---", result)
                  this.SaveFile(result, reader, file, event)
                },
                error => {
                  console.log("errrrrr1", error)
                  // this._toaster.pop('error', 'Error', error['reason']);
                  this.ng2ImgMax.compressImage(file, 0.800, false, true).subscribe(
                    result => {
                      //console.log("result---", result)
                      this.SaveFile(result, reader, file, event)
                    },
                    error => {
                      console.log("errrrrr1", error)
                      // this._toaster.pop('error', 'Error', error['reason']);
                      this.ng2ImgMax.compressImage(file, 1, false, true).subscribe(
                        result => {
                          //console.log("result---", result)
                          this.SaveFile(result, reader, file, event)
                        },
                        error => {
                          console.log("errrrrr3", error)
                          this.SaveFile(error.compressedFile, reader, file, event)
                        }
                      );
                    }
                  );
                }
              );
            }

          } else {
            this.images_to_upload--;
            //console.log("444")
            this.image_error = false;
            this.imageTypeError.emit(true);
            this._toaster.pop('error', 'Error', 'Image must be PNG and JPG formats');
          }
        }
        else {
          this.images_to_upload--;
          this._toaster.pop('error', 'Error', 'Please select an image');
        }
      });
    }
  }
  SaveFile(result, reader, file, event) {
    this.upload_image = new File([result], result.name);
    console.log("upload_image", this.upload_image)
    var env = this;
    reader.readAsDataURL(this.upload_image);
    reader.onload = (theFile) => {
      // console.log("ssssssss");
      env.target_image_data = { "filetype": file.type, "filename": this.upload_image.name, "filesize": this.upload_image.size, "imageBase64": reader['result'].toString().split(',')[1] };
      env.target_image_data['type'] = event.srcElement.attributes.name.value;
      env.target_image_data['currentDate'] = moment().startOf('day').unix();
      //console.log(" env.target_image_data --", env.target_image_data)
      if (env.target_image_data['type'] === 'food') {
        this._imageService.nutrition_images = [...[{ imageBase64: reader['result'].toString().split(',')[1] }], ...this._imageService.nutrition_images]
        this.images_iterated++;
        console.log(this.images_iterated, this.images_to_upload)
        if (this.images_iterated === this.images_to_upload) {
          this.imageData.emit();
        }
      } else if (env.target_image_data['type'] === 'profile_image') {
        this.addUpdateProfilePhoto(env.target_image_data);
      } else {
        env.fileService.setImageData(env.target_image_data, event.srcElement.attributes.name.value);
        this.AddBodyShots(env.target_image_data);
      }
    }
  }

  /*- checks if word exists in array -*/
  isInArray(array, word) {
    return array.indexOf(word.toLowerCase()) > -1;
  }

  AddBodyShots(image_data) {
    this._api.addBodyShots(image_data)
      .subscribe(data => {
        //console.log("addBodyShots", data);
        if (data['code'] === 200) {
          this.imageData.emit(data);
          this._toaster.pop('success', 'Success', 'Added successfully');
        } else {
          this._toaster.pop('error', 'Error', 'Error in uploading');
        }
      }, error => {
        this._toaster.pop('error', 'Error', 'Internal server error');
      });
  }
  addUpdateProfilePhoto(image_data) {
    this._api.addUpdateProfilePhoto({ base64: image_data.imageBase64 })
      .subscribe(data => {
        console.log("addUpdateProfilePhoto", data);
        if (data['msg']) {
          this._user.user.profilePicURL = data['msg'];
          this._user.setUser(this._user.user)
          this.imageData.emit(data['msg']);
          this._toaster.pop('success', 'Success', 'Added successfully');
        } else {
          this._toaster.pop('error', 'Error', 'Error in uploading');
        }
      }, error => {
        this._toaster.pop('error', 'Error', 'Internal server error');
      });
  }
}