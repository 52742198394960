import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomValidatorService } from './custom-validator.service';

@Injectable()
export class HttpService {
    public env = environment;
    headers;
    constructor(private http: HttpClient, public _customValidators: CustomValidatorService) {
    }

    get(url, params = {}) {
        return this.http.get(this.env.apiUrl + url, this.getHeaders(params));
    }

    post(url, data) {
        return this.http.post(this.env.apiUrl + url, data, this.getHeaders());
    }

    delete(url) {
        return this.http.delete(this.env.apiUrl + url, this.getHeaders());
    }

    put(url, data) {
        return this.http.put(this.env.apiUrl + url, data, this.getHeaders());
    }
    setHeaders(params = {}) {
        let userdataFromLogin = this._customValidators.getUserData();
        // console.log("userdataFromLogin****-------------", userdataFromLogin)
        if (localStorage.getItem('user')) {
            let user = JSON.parse(localStorage.getItem('user'));
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + user['token'],
                    'UserId': user['userId'].toString(),
                    'token': user['token'],
                    "X-Requested-With": "XMLHttpRequest",
                    "WWW-Authenticate": "xBasic",
                    "language": localStorage.getItem('lang') || 'en'
                }),
                params: params
            };
            return this.headers = httpOptions;
        } else if (userdataFromLogin) {
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(userdataFromLogin['email'] + ':' + userdataFromLogin['password']),
                    "X-Requested-With": "XMLHttpRequest",
                    "WWW-Authenticate": "xBasic"
                }),
                params: params
            };
            return this.headers = httpOptions;
        } else {
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    // 'Authorization': 'dGVzdHVzZXJAZ21haWwuY29tOmFkbWluQDEyMw==',
                    'Authorization': btoa(this.env.app_id + ':' + this.env.app_token),
                    "X-Requested-With": "XMLHttpRequest",
                    "WWW-Authenticate": "xBasic"

                }),
                params: params
            };
            return this.headers = httpOptions;
        }
    }
    getHeaders(params = {}) {
        return this.setHeaders(params['params']);
    }
}