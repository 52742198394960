import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../service/web-api.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { UserAuthService } from '../service/user/user-auth.service';
import { LanguageTransalte } from '../service/language-translate.service';
@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {
  coupon;
  amount = 9.99 * 1.05;
  subscriptionType = 'monthly'
  additionalInfo;
  constructor(public _api: WebApiService,
    public _router: Router,
    public _user: UserAuthService,
    public _toaster: ToasterService,
    public _translate: LanguageTransalte) { }
  ngOnInit() {
    if (this._user.user.isSubscriptionActive) {
      this._toaster.pop('warning', '', this._translate.getTranslation('ToasterMessages.AnnualSubscriptionActive'));
      this._router.navigate(['/dashboard']);
    }
  }
  CheckCoupon() {
    if (this.coupon) {
      // going to check coupon
      this._api.getPromoCodeDetail({ code: this.coupon })
        .subscribe(data => {
          console.log("data", data);
          this.subscriptionType = data['subscriptionType']
          if (data['totalAmount'] || data['totalAmount'] === 0.0) {
            this.amount = data['totalAmount'];
            this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.PromoCodeApplied'));
          } else {
            this.SetFee();
          }
          if (data && data['additionalInfo']) {
            this.additionalInfo = data['additionalInfo']
            this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.PromoCodeApplied'));
          } else {
            this.additionalInfo = ''
          }
        }, err => {
          if (err['error']) {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), err['error'])
          }
          this.SetFee();
        })
    } else {
      this.subscriptionType = 'monthly'
      this.SetFee();
      this.additionalInfo = ''
    }
  }
  GoWithSubscriptionPlan() {
    localStorage.setItem('subscriptionType', this.subscriptionType);
    localStorage.setItem('amount', JSON.stringify(this.amount ? true : false));
    if (this.coupon) {
      localStorage.setItem('code', this.coupon)
    } else {
      localStorage.setItem('code', '')
    }
    console.log("Helw")
    this._router.navigate(['/profile/cardDetails']);
  }
  ChangeSubscription(new_type) {
    if (new_type !== this.subscriptionType) {
      this.subscriptionType = new_type;
      this.SetFee();
      this.coupon = '';
      this.additionalInfo = null;
    }
  }
  SetFee() {
    if (this.subscriptionType === 'monthly') {
      this.amount = 9.99 * 1.05
    } else {
      this.amount = 9.99 * 12 * 1.05
    }
  }
}
