import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { WebApiService } from '../service/web-api.service';
import { LanguageTransalte } from '../service/language-translate.service';
declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  button_clicked;
  focusIn = false;
  constructor(public fb: FormBuilder,
    public _toaster: ToasterService,
    public _router: Router,
    public _api: WebApiService,
    public _translate: LanguageTransalte) {
  }
  ngOnInit() {
    this.button_clicked = false;
    this.forgotPasswordForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, CustomValidators.email])]
    });

    $(".fake-autofill-fields").show();
    // some DOM manipulation/ajax here
    window.setTimeout(function () {
      $(".fake-autofill-fields").hide();
    }, 1);
  }

  changeCss() {
    this.focusIn = true;
  }

  // forgot password
  forgotPassword() {
    this.button_clicked = true;
    let form_data = this.forgotPasswordForm.value;
    if (this.forgotPasswordForm.valid) {
      const data = { 'email': form_data['email'] };
      this._api.forgotPassword(data)
        .subscribe(data => {
          console.log(data);
          if (data['code'] === 200) {
            this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.EmailSent'));
            this._router.navigate(['/login']);
          } else {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.NoUser'));
          }
        }, error => {
          this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.InternalServerError'));
        });
    }
  }
  GoTo(link) {
    this._router.navigate([link]);
  }
}