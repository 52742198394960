import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CanActivate, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
@Injectable({
    providedIn: 'root'
})
export class UserAuthService {
    public user$: Subject<any> = new Subject<any>();
    public user;
    public _userDataBlackFriday: Subject<any> = new Subject<any>();
    public userDataBlackFriday;
    constructor(public router: Router) {
        this.user$.subscribe(data => {
            this.user = data;
        });
        const user = localStorage.getItem('user');
        if (user) {
            this.user$.next(JSON.parse(user));
        }
        this._userDataBlackFriday.subscribe(data => {
            this.userDataBlackFriday = data;
        })
        const userDataBlackFriday = localStorage.getItem('black-friday');
        if(userDataBlackFriday) {
            this._userDataBlackFriday.next(JSON.parse(userDataBlackFriday));
        }
    }
    setUser(user) {
        console.log('setting user in local storage', user);
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            this.user$.next(user);
        }
    }
    resetUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('pushTokenSaved');
        this.user$.next(null);
    }
    GetTimeZoneOffset() {
        return new Date().getTimezoneOffset() * 60
    }
    datePickerConfiguration() {
        return {
            timepicker: false,
            initTime: false,
            format: 'M d, Y',
            maxDate: new Date(),
            minDate: new Date(this.user['userCreatedAt']),
            yearStart: 1900,
            yearEnd: new Date().getFullYear(),
        }
    }
    setUserDataBlackFriday(data) {
        localStorage.setItem('black-friday', JSON.stringify(data));
        this._userDataBlackFriday.next(data);
    }
    resetUserDataBlackFriday() {
        this._userDataBlackFriday.next(null);
    }
}
export const Languages = [
    {
        name: 'English',
        key: 'en'
    },
    {
        name: 'French',
        key: 'fr'
        // },
        // {
        //     name: 'Hindi',
        //     key: 'hn'
        // },
        // {
        //     name: 'Korean',
        //     key: 'kr'
        // },
        // {
        //     name: 'Spanish',
        //     key: 'sp'
    }
]
@Injectable()
export class IsWebLoggedIn implements CanActivate {
    constructor(public router: Router) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}
@Injectable()
export class CanLogIn implements CanActivate {
    constructor(public router: Router, public _toaster: ToasterService) { }
    canActivate() {
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(user);
        if (user && (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired))) {
            if (!user.gender) {
                // this._toaster.pop('error', 'Error', 'Please select your gender');
                this.router.navigate(['/started']);
            } else {
                this.router.navigate(['/introductory']);
            }
            return false;
        } else if (user && !user.isPlanCreated) {
            this.router.navigate(['/profile']);
            return false;
        } else if (user) {
            this.router.navigate(['/dashboard']);
            return false;
        } else {
            return true;
        }
    }
}