import { Component, OnInit } from '@angular/core';
import { ToasterConfig, ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { WebApiService } from './service/web-api.service';
import { UserAuthService } from './service/user/user-auth.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare function setNameAndEmail(x, y, z): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  userDetails;
  show_splash = true;
  show_side_bar = false;
  WebDesignedUrls = ["find-my-dominant-harmone"];
  constructor(public translate: TranslateService,
    public title: Title,
    public _api: WebApiService,
    public _toaster: ToasterService,
    public _user: UserAuthService,
    public _router: Router) {
    translate.addLangs(['en', 'fr', 'gr', 'hn', 'pb']);
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr|gr|hn|pb/) ? browserLang : 'en');
    if (localStorage.getItem('lang')) {
      translate.use(localStorage.getItem('lang'));
    }
  }
  ngOnInit() {
    // this.showFullScreen = true;
    this.getUserDetails();
    this.userDetails && setNameAndEmail(this.userDetails.fName + ' ' + this.userDetails.lName, this.userDetails.email, environment.tawkToApiKey);
    this._router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.setTitle();
    });
    this.setTitle();

    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        $('body').scrollTop(0)
        if (event.url.indexOf('/nutrition/add') !== -1) {
          $("body").addClass('nutrition-datepicker');
        } else {
          $("body").removeClass('nutrition-datepicker');
        }
      }
    });
    this.CheckSize();
    window.addEventListener('resize', () => {
      this.CheckSize();
    });
    console.log(!this._user.user)
  }

  CheckSize() {
    if (screen.width < 1200) {
      this.show_side_bar = false;
    } else {
      this.show_side_bar = true;
    }
  }
  ChangeLanguage() {
    this.translate.use('gr');
  }
  public config: ToasterConfig =
    new ToasterConfig({
      showCloseButton: true,
      tapToDismiss: false,
      timeout: 5000,
      limit: 1,
      positionClass: 'toast-top-left'
    });
  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }
    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }
  getUserDetails() {
    this._api.getUserDetails(``)
      .subscribe((data) => {
        if (data) {
          this.userDetails = data;
        }
      }, err => {
        console.log(err);
      })
  }
  setTitle() {
    var title = this.getTitle(this._router.routerState, this._router.routerState.root).join('-') + " | Fantastic Life";
    this.title.setTitle(title);
  }

  CheckToShowWebisteDesign() {
    if (window.location.pathname.indexOf("black-friday") != -1 || window.location.pathname.indexOf("unsubscribe") != -1) {
      return true;
    } else {
      return this.WebDesignedUrls.filter(url => window.location.pathname.indexOf(url) !== -1).length > 0
    }
  }
}
