import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../service/user/user-auth.service';
import { ToasterService } from 'angular2-toaster';
import { WebApiService } from '../service/web-api.service';
import * as moment from 'moment';
import * as $ from 'jquery';

@Component({
  selector: 'app-started-screen',
  templateUrl: './started-screen.component.html',
  styleUrls: ['./started-screen.component.scss']
})
export class StartedScreenComponent implements OnInit, AfterViewInit {
  ip_address;
  constructor(public _router: Router,
    public _user: UserAuthService,
    public _toaster: ToasterService,
    public _api: WebApiService) { }

  ngOnInit() {
    if (localStorage.getItem('answers')) {
      this._router.navigate(['/questionnaire']);
    }
    $.getJSON("https://jsonip.com?callback=?", data => {
      this.ip_address = data.ip;
      console.log("Your IP address is :- " + data.ip, this.ip_address);
    });
    this._api.setUserLanguage({ language: localStorage.getItem('lang') })
      .subscribe(data => {
        console.log(data);
      }, err => {
        console.log(err);
      })
  }

  ngAfterViewInit() {
    setTimeout(_ => {
     this._router.navigate(['/questionnaire']);
    }, 5000)
  }


  goToIntroductoryPage() {
    console.log("this.ip_address----", this.ip_address)
    const data_to_send = {
      acknowledgement: "yes",
      aknowlegementDateTime: moment().unix(),
      ipWhenAcknowledge: this.ip_address
    };
    console.log("data_to_send--", data_to_send)
    this._api.introductory(data_to_send)
      .subscribe(data => {
        if (this._user.user && (!this._user.user.gender || !(this._user.user.gender === 'Male' || this._user.user.gender === 'Female'))) {
          this._router.navigate(['/profile-info']);
        } else {
          this._router.navigate(['/introductory']);
        }
      }, error => {
        this._toaster.pop('error', 'Error', 'Internal server error');
      });
  }
}
