import { Injectable } from '@angular/core';

@Injectable()
export class ImagesService {
    nutrition_images = [];
    constructor() { }
    setNutritionImages(base64data) {
        this.nutrition_images.push({ imageBase64: base64data })
        console.log(this.nutrition_images);
    }
    resetNutritionImages() {
        this.nutrition_images = [];
    }
}