import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../service/user/user-auth.service';
import * as moment from 'moment';
import {WebApiService} from '../service/web-api.service';

@Component({
  selector: 'app-desktop-sidebar',
  templateUrl: './desktop-sidebar.component.html',
  styleUrls: ['./desktop-sidebar.component.scss']
})
export class DesktopSidebarComponent implements OnInit {
    profile_image = false;
    sideBarItems = [];
    previousMenuItem = 0;
    front_img_type_error: any;
    userAdmin = false;
  constructor(public _router: Router,
    public _user: UserAuthService,
    public _api: WebApiService) { }

  ngOnInit() {
    this.sideBarItems = [{
      "menuItem" : "home",
      "cls" : "active"
    }, {
      "menuItem" : "plan",
      "cls" : "notActive"
    }, {
      "menuItem" : "notification",
      "cls" : "notActive"
    }, {
      "menuItem" : "profile",
      "cls" : "notActive"
    }, {
        "menuItem" : "admin",
        "cls" : "notActive"
    }]
    console.log(this._user.user);
    this.getUserDetails();
    this.isAdmin();
  }

  getUserDetails() {
    this._api.getUserDetails(``)
    .subscribe(data => {
        console.log(data);
        if (data) {
            this.profile_image = data['profilePhotoURL'];
        }
    }, err => {
        console.log(err);
    })
  }

  isAdmin() {
    this._api.isAdmin(``)
    .subscribe(data => {
      console.log(data);
      if(data && data['isAdmin']) {
        this.userAdmin = data['isAdmin'];
      }
    }, err => {
      console.log(err);
    })
  }

  changeActiveClass(index) {
    if(index != this.previousMenuItem) {
      this.sideBarItems[this.previousMenuItem]['cls'] = 'notActive';
      this.sideBarItems[index]['cls'] = 'active';
      this.previousMenuItem = index;
    }
  }

  Goto(link) {
    this._router.navigate([link]);
  }
}
