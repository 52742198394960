import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-point-marker',
  templateUrl: './point-marker.component.html',
  styleUrls: ['./point-marker.component.scss']
})
export class PointMarkerComponent implements OnInit {
 @Input() points: any;
  constructor() { }

  ngOnInit() {
  }

}
