import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-plus-icon',
  templateUrl: './plus-icon.component.html',
  styleUrls: ['./plus-icon.component.scss']
})
export class PlusIconComponent implements OnInit {
  status = false;
  constructor() { }

  ngOnInit() {
  }
  changePlusIcon() {
    $('body').toggleClass('active-nav');
  }

}
