import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../service/web-api.service';
import _ from 'underscore';
import { UserAuthService } from '../service/user/user-auth.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
declare var $: any;
import * as moment from 'moment';
import { LanguageTransalte } from '../service/language-translate.service';

@Component({
  selector: 'app-questionare',
  templateUrl: './questionare.component.html',
  styleUrls: ['./questionare.component.scss']
})
export class QuestionareComponent implements OnInit {
  answers;
  body_questions;
  sleep_questions;
  nutrition_questions;
  mind_questions;
  medicine_question;
  question_type = 1;
  questions_to_show = [];
  percentage = 0;
  all_questions = [];
  toggleResponse = false;
  selected_question = {};
  on_in_it = true;
  isDesktopScreen;
  constructor(
    public _api: WebApiService,
    public _toaster: ToasterService,
    public _router: Router,
    public _user: UserAuthService,
    public _translate: LanguageTransalte) { }

  ngOnInit() {
    if (document.body.clientWidth <= 1200) {
      this.isDesktopScreen = false;
    } else {
      this.isDesktopScreen = true;
    }
    $('.js-candlestick').candlestick();
    setTimeout(_ => {
      $('.js-candlestick').candlestick();
    }, 0)
    $(document).ready(function () {
      setTimeout(() => {
        $('.owl-carousel').owlCarousel({
          center: true,
          items: 1,
          loop: true,
          margin: 10,
          responsive: {
            640: {
              items: 2
            }
          }
        });
      }, 1000);
    });

    this.answers = [];
    this.sleep_questions = [];
    this.nutrition_questions = [];
    this.body_questions = [];
    this.mind_questions = [];
    this.medicine_question = [];
    if (localStorage.getItem('answers')) {
      const localStorageAnswers = localStorage.getItem('answers');
      this.answers = JSON.parse(localStorageAnswers);
    }
    this.GetQuestions();
  }

  ShowToggle(question) {
    let action = 'noset';
    let steps = 3;
    if (this.answers) {
      if (this.answers[question['questionnaireId']]) {
        if (this.answers[question['questionnaireId']] === '0') {
          action = 'on';
          steps = 2;
        } else if (this.answers[question['questionnaireId']] === '1') {
          action = 'off';
          steps = 2;
        }
      }
    }
    if (!this.isDesktopScreen && $("#example-" + question['questionnaireId']) && $("#example-" + question['questionnaireId'])[0]) {
      $("#example-" + question['questionnaireId']).setTheSwitch({
        action: action,
        steps: steps,
        // background colors
        bgOn: '#4da154',
        bgNoSet: '#f1f1f1',
        bgOff: '#d82c2c',
        // width
        width: 60,
        // height
        height: 14,
        // uses percentage
        porcent: false,
        // on/off labels
        onLabel: '●',
        offLabel: '●',
        // handler height
        hsize: 11,
        // is disabled?
        disabled: false,
        onSet: e => {
          var $carousel;
          $carousel = $('.questionnaire-slider-pwa').flickity();
          $carousel.flickity('select', this.questions_to_show.length - 1);
        },
        onClickOn: e => {
          if (document.getElementById(e[0].id + '_railnoset')) {
            $("#" + e[0].id).setTheSwitchSteps()
            document.getElementById(e[0].id + '_railnoset').remove()
          }
          this.DataChanged(parseInt(e[0].id.replace('example-', '')), '1');
          this.on_in_it = false;
        },
        onClickNoSet: e => { console.log(e) },
        onClickOff: e => {
          if (document.getElementById(e[0].id + '_railnoset')) {
            $("#" + e[0].id).setTheSwitchSteps()
            document.getElementById(e[0].id + '_railnoset').remove()
          }
          this.DataChanged(parseInt(e[0].id.replace('example-', '')), '0');
          this.on_in_it = false;
        }
      });
    }
    if (this.isDesktopScreen && $("#example-d-" + question['questionnaireId']) && $("#example-d-" + question['questionnaireId'])[0]) {
      $("#example-d-" + question['questionnaireId']).setTheSwitch({
        action: action,
        steps: steps,
        // background colors
        bgOn: '#4da154',
        bgNoSet: '#f1f1f1',
        bgOff: '#d82c2c',
        // width
        width: 60,
        // height
        height: 14,
        // uses percentage
        porcent: false,
        // on/off labels
        onLabel: '●',
        offLabel: '●',
        // handler height
        hsize: 11,
        // is disabled?
        disabled: false,
        onSet: e => {
          var $carousel;
          $carousel = $('.questionnaire-slider-desktop').flickity();
          $carousel.flickity('select', this.questions_to_show.length - 1);
        },
        onClickOn: e => {
          if (document.getElementById(e[0].id + '_railnoset')) {
            $("#" + e[0].id).setTheSwitchSteps()
            document.getElementById(e[0].id + '_railnoset').remove()
          }
          this.DataChanged(parseInt(e[0].id.replace('example-d-', '')), '1');
          this.on_in_it = false;
        },
        onClickNoSet: e => { console.log(e) },
        onClickOff: e => {
          if (document.getElementById(e[0].id + '_railnoset')) {
            $("#" + e[0].id).setTheSwitchSteps()
            document.getElementById(e[0].id + '_railnoset').remove()
          }
          this.DataChanged(parseInt(e[0].id.replace('example-d-', '')), '0');
          this.on_in_it = false;
        }
      });
    }
  }

  GetQuestions() {
    this._api.GetQuestions()
      .subscribe(data => {
        this.all_questions = data as any;
        this.sleep_questions = _.filter(data, element => element['activityName'] === 'Sleep');
        this.nutrition_questions = _.filter(data, element => element['activityName'] === 'Nutrition');
        this.body_questions = _.filter(data, element => element['activityName'] === 'Body');
        this.medicine_question = _.filter(data, element => element['activityName'] === 'Medicine');
        this.mind_questions = _.filter(data, element => element['activityName'] === 'Mind');
        this.all_questions.forEach(question => {
          if (question['description']) {
            question['description_points'] = this.FormatDescription(question['description'])
            question['description_points'].forEach((element1, index) => {
              if (element1.indexOf('<..>') !== -1) {
                question['description_points'][index] = element1.split('<..>')[0];
                question['subpoints'] = {};
                question['subpoints'][index] = element1.substring(element1.indexOf('<..>') + 4, element1.length).split('<..>')
              }
            });
            question['description_basic_points'] = this.FormatDescription(question['description'])
            if (question['description_points'].join('').length > 100) {
              question['know_more'] = true;
              question['description_basic_points'] = this.FormatDescription(question['description'].substring(0, 100))
              question['description_basic_points'].forEach(element => {
                if (element.indexOf('<..>') !== -1) {
                  element = element.replace('<..>', ': ')
                  element = element.split('<..>').join(', ');
                }
              });
            } else {
              question['know_more'] = false;
            }
          } else {
            question['description_points'] = []
            question['description_basic_points'] = []
            question['know_more'] = false;
          }
        });
        this.CheckToShowQuestions();
      }, error => {
      });
  }

  DataChanged(key, value) {
    setTimeout(() => {
      this.answers[key] = value;
      localStorage.setItem('answers', JSON.stringify(this.answers));
      this.CheckToShowQuestions();
    }, 0);
  }

  EnableSaveButton(array) {
    let valid = true;
    array.forEach(question => {
      if (!this.answers[question['questionnaireId']]) {
        valid = false;
      }
    });
    return valid;
  }

  SaveQuestions() {
    const answersReqBody = [];
    for (let i = 1; i < this.answers.length; i++) {
      if (this.answers[i]) {
        answersReqBody.push({
          'questionnaireId': i,
          'answerTypeBoolean': this.answers[i] === '1' ? 'true' : 'false'
        });
      }
    }
    console.log('Answers: ' + JSON.stringify(answersReqBody));
    if (this.EnableSaveButton(this.mind_questions)) {
      this._api.SaveQuestions(answersReqBody)
        .subscribe(data => {
          if (data['code'] === 200) {
            this._user.user.hasUserFilledAnswersAtleastOnce = true;
            this._user.user.isAnswersRequired = false;
            this._user.user.peopleGroupName = data['peopleGroupName'];
            this._user.user.isPlanCreated = true;
            this._user.user.PlanCreatedAtEpoch = moment().unix();
            this._user.user.isProfileUpdateRequired = false;
            this._user.setUser(this._user.user);
            this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.AnswerSubmittedSuccess'));
            localStorage.removeItem('answers');
            this._router.navigate(['/bodytype']);
          } else {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), data['msg']);
          }
        }, error => {
          console.log(error);
        });
    }
  }

  CheckToShowQuestions() {
    if (this.answers) {
      if (this.EnableSaveButton(this.medicine_question)) {
        this.question_type = 5;
      } else if (this.EnableSaveButton(this.nutrition_questions)) {
        this.question_type = 4;
      } else if (this.EnableSaveButton(this.sleep_questions)) {
        this.question_type = 3;
      } else if (this.EnableSaveButton(this.body_questions)) {
        this.question_type = 2;
      }
    }
    var new_questions = []
    if (this.question_type === 1) {
      this.questions_to_show = this.body_questions.filter(question => this.answers[question['questionnaireId']]);
      new_questions = this.body_questions.filter(question => !this.answers[question['questionnaireId']])
    } else if (this.question_type === 2) {
      this.questions_to_show = this.sleep_questions.filter(question => this.answers[question['questionnaireId']])
      new_questions = this.sleep_questions.filter(question => !this.answers[question['questionnaireId']])
    } else if (this.question_type === 3) {
      this.questions_to_show = this.nutrition_questions.filter(question => this.answers[question['questionnaireId']])
      new_questions = this.nutrition_questions.filter(question => !this.answers[question['questionnaireId']])
    } else if (this.question_type === 4) {
      this.questions_to_show = this.medicine_question.filter(question => this.answers[question['questionnaireId']])
      new_questions = this.medicine_question.filter(question => !this.answers[question['questionnaireId']])
    } else {
      this.questions_to_show = this.mind_questions.filter(question => this.answers[question['questionnaireId']])
      new_questions = this.mind_questions.filter(question => !this.answers[question['questionnaireId']])
    }

    if (new_questions.length > 0) {
      this.questions_to_show.push(new_questions[0])
      setTimeout(() => {
        if (this.on_in_it) {
          this.questions_to_show.forEach(question => {
            this.ShowToggle(question)
          })
        }
        this.ShowToggle(new_questions[0])
      }, 1000);
    }
    this.percentage = parseInt(((this.all_questions.filter(question => this.answers[question['questionnaireId']]).length / this.all_questions.length) * 100).toFixed(0));
    if (this.percentage == 100) {
      setTimeout(() => {
        $('body').scrollTop(document.body.scrollHeight)
      }, 1000);
    }
  }

  FormatDescription(description) {
    return description.split('<.>');
  }
}
