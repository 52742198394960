import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class LanguageTransalte {
    constructor(public translate: TranslateService) {
        
    }

    getTranslation(id) {
        let str;
        this.translate.get(id).subscribe(data => {
            str = data;
        })
        return str;
    }
}