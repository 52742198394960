
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

import {Subject} from 'rxjs/Subject';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class LoaderService {
    spinner$: Subject<any> = new Subject<any>();

    constructor() {
    }

    public show() {
        this.spinner$.next(true);
    }

    public hide() {
        this.spinner$.next(false);
    }
}
