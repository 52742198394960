import { Component, OnInit } from '@angular/core';
import { WebApiService } from '../service/web-api.service';
import { UserAuthService } from '../service/user/user-auth.service';
import { ToasterService } from 'angular2-toaster';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-servey',
  templateUrl: './servey.component.html',
  styleUrls: ['./servey.component.scss']
})
export class ServeyComponent implements OnInit {
  questions_to_show = [];
  all_questions = [];
  answers = [];
  selected_question = {};
  percentage = 0;
  step = 1;
  peopleGroupName = '';
  questionnaire_rating = 0;
  mobileNumber;
  user = {
    email: '',
    firstName: '',
    lastName: ''
  };
  surveyUserId = null;
  constructor(public _api: WebApiService,
    public _toaster: ToasterService,
    public _router: Router,
    public _routes: ActivatedRoute,
    public _user: UserAuthService) { }

  ngOnInit() {
    this.step = 1;
    if (this._user.user && !this._user.user.isSalesMan) {
      this._toaster.pop('error', 'Error', "You are requesting to the unauthorize page.")
      this._router.navigate(['/profile']);
    }
    if (!this._user.user && this.GetMobileNumberFromUrl() === "") {
      this._toaster.pop('error', 'Error', "You are requesting to the unauthorize page.")
      this._router.navigate(['/signup']);
    }
    if (this.GetMobileNumberFromUrl() !== "") {
      this.step = 2;
      this.getSurveyQuestions();
    }
  }
  getSurveyQuestions() {
    this._api.getSurveyQuestions()
      .subscribe(data => {
        let dataa: any = data;
        this.all_questions = dataa;
        this.all_questions.forEach(question => {
          question['description_points'] = this.FormatDescription(question['description'])
          question['description_points'].forEach((element1, index) => {
            if (element1.indexOf('<..>') !== -1) {
              question['description_points'][index] = element1.split('<..>')[0];
              question['subpoints'] = {};
              question['subpoints'][index] = element1.substring(element1.indexOf('<..>') + 4, element1.length).split('<..>')
            }
          });

          question['description_basic_points'] = this.FormatDescription(question['description'])
          if (question['description_points'].join('').length > 100) {
            question['know_more'] = true;
            question['description_basic_points'] = this.FormatDescription(question['description'].substring(0, 100))
            question['description_basic_points'].forEach(element => {
              if (element.indexOf('<..>') !== -1) {
                element = element.replace('<..>', ': ')
                element = element.split('<..>').join(', ');
              }
            });
          } else {
            question['know_more'] = false;
          }
        })
        this.CheckToShowQuestions()
      }, err => {
        console.log(err);
      })
  }
  ShowToggle(question) {
    let action = 'noset';
    let steps = 3;
    if (this.answers) {
      if (this.answers[question['questionnaireId']]) {
        if (this.answers[question['questionnaireId']] === '0') {
          action = 'on';
          steps = 2;
        } else if (this.answers[question['questionnaireId']] === '1') {
          action = 'off';
          steps = 2;
        }
      }
    }
    if ($("#example-" + question['questionnaireId']) && $("#example-" + question['questionnaireId'])[0]) {
      $("#example-" + question['questionnaireId']).setTheSwitch({
        action: action,
        steps: steps,
        // background colors
        bgOn: '#4da154',
        bgNoSet: '#f1f1f1',
        bgOff: '#d82c2c',
        // width
        width: 60,
        // height
        height: 14,
        // uses percentage
        porcent: false,
        // on/off labels
        onLabel: '●',
        offLabel: '●',
        // handler height
        hsize: 11,
        // is disabled?
        disabled: false,
        onSet: e => {
          var $carousel = $('.questionnaire-slider').flickity();
          $carousel.flickity('select', this.questions_to_show.length - 1);
        },
        onClickOn: e => {
          if (document.getElementById(e[0].id + '_railnoset')) {
            $("#" + e[0].id).setTheSwitchSteps()
            document.getElementById(e[0].id + '_railnoset').remove()
          }
          this.DataChanged(parseInt(e[0].id.replace('example-', '')), '1');
        },
        onClickNoSet: e => { },
        onClickOff: e => {
          if (document.getElementById(e[0].id + '_railnoset')) {
            $("#" + e[0].id).setTheSwitchSteps()
            document.getElementById(e[0].id + '_railnoset').remove()
          }
          this.DataChanged(parseInt(e[0].id.replace('example-', '')), '0');
        }
      });
    }
  }
  DataChanged(key, value) {
    setTimeout(() => {
      this.answers[key] = value;
      this.CheckToShowQuestions();
    }, 0);
  }
  FormatDescription(description) {
    return description.split('<.>')
  }
  CheckToShowQuestions() {
    var new_questions = []
    this.questions_to_show = this.all_questions.filter(question => { return this.answers[question['questionnaireId']] });
    new_questions = this.all_questions.filter(question => !this.answers[question['questionnaireId']])
    if (new_questions.length > 0) {
      this.questions_to_show.push(new_questions[0])
      setTimeout(() => {
        this.ShowToggle(new_questions[0])
      }, 1000);
    }
    this.percentage = parseInt(((this.all_questions.filter(question => this.answers[question['questionnaireId']]).length / this.all_questions.length) * 100).toFixed(0));
    if (this.percentage == 100) {
      setTimeout(() => {
        $('body').scrollTop(document.body.scrollHeight)
      }, 1000);
    }
  }
  saveSurveyQuestionnaireAnswer() {
    let array = [];
    for (let i = 1; i < this.answers.length; i++) {
      if (this.answers[i]) {
        let priority = this.all_questions.filter(question => question['questionnaireId'] === i)[0]['priority']
        array.push({
          "questionId": i,
          "answer": this.answers[i] === "1",
          "priority": priority
        })
      }
    }
    this._api.saveSurveyQuestionnaireAnswer({ questionnaireAnswer: array, mobileNumber: this.GetMobileNumberFromUrl() })
      .subscribe(data => {
        this.step = 3;
        this.peopleGroupName = data['peopleGroupName']
        this.surveyUserId = data['surveyUserId'];
        this._toaster.pop('success', 'Success', "Body Group Detected");
      }, error => {
      });
  }
  saveSurveyRating() {
    this._api.saveSurveyRating({ surveyUserId: this.surveyUserId, mobileNumber: this.GetMobileNumberFromUrl(), rating: this.questionnaire_rating })
      .subscribe(data => {
        console.log(data);
      }, err => {
        console.log(err);
      })
  }
  sendSurveyTextMessage() {
    if (!this.mobileNumber) {
      this._toaster.pop('error', 'Error', 'Please fill the phone numebr')
      return;
    }
    var PhoneNumberFormat = /^^\+[1-9]{1}[0-9]{7,11}$/;
    if (this.mobileNumber && !this.mobileNumber.match(PhoneNumberFormat)) {
      this._toaster.pop('error', 'Error', 'Please enter a valid phone number with country code')
      return;
    }
    this._api.sendSurveyTextMessage({ surveyUserId: this.surveyUserId, mobileNumber: this.mobileNumber, link: 'Know your body type now ' + environment.domain_name + '/find-my-dominant-hormone?mob=' + this.mobileNumber })
      .subscribe(data => {
        console.log(data);
      }, err => {
        console.log(err);
        if (err['status'] === 200) {
          this._toaster.pop('success', 'Success', 'Message is sent on your Mobile. You can check your device');
          this._router.navigate(['/profile'])
        } else {
          this._toaster.pop('error', 'Error', err['error']);
        }
      })
  }
  receiveBasicPlan() {
    if (!this.user['email']) {
      this._toaster.pop('error', 'Error', 'Please fill the email address')
      return;
    }
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (this.user['email'] && !this.user['email'].match(mailformat)) {
      this._toaster.pop('error', 'Error', 'Please enter a valid email address')
      return;
    }
    if (!this.user['firstName'] || this.user['firstName'].trim() === "") {
      this._toaster.pop('error', 'Error', 'Please fill the first name')
      return;
    }
    if (!this.user['lastName'] || this.user['lastName'].trim() === "") {
      this._toaster.pop('error', 'Error', 'Please fill the last name')
      return;
    }
    this.user['surveyUserId'] = this.surveyUserId
    this._api.receiveBasicPlan(this.user)
      .subscribe(data => {
        console.log(data);
      }, err => {
        console.log(err);
        this._toaster.pop('success', 'Success', 'A basic plan is sent to the provided email address.');
        document.getElementById('myModal-promotion').style.display = "none"
        document.getElementsByClassName('modal-backdrop')[0].remove()
        if (this.GetMobileNumberFromUrl() === "") {
          this._router.navigate(['/profile'],{ queryParams: { refresh: true } })
        } else {
          this._router.navigate(['/signup'])
        }
      })
  }
  GetMobileNumberFromUrl() {
    return this._routes.snapshot.queryParams.mob || ""
  }
}