import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})



export class FooterComponent implements OnInit {
 
  constructor(public _router: Router) { }

  ngOnInit() {
    // $('.user-info span').click( function () {
    //   $('.fixed-bottom-link').css({
    //       position:'absolute',
    //       bottom:0
    //   });
    // });
    
  }
  ActiveIfContains(link){
    return this._router.url.indexOf(link) !== -1
  }
}
