// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// tslint:disable:max-line-length
export const environment = {
  production: false,
  apiUrl: 'https://dev-api.fantasticlife.online',
  app_id: 'Fantastic',
  app_token: 'f@n1@51ic',
  stripe_pk: 'pk_test_L8cOpmBvKm06CFMcSBiPBDBu',
  nutritionix_APPID: '16203d7f',
  nutritionix_APPKEY: '939c2c2a040764a0d11df79c3187bc14',
  // nutritionix_APPID: '150b1c6b',
  // nutritionix_APPKEY: '27bf998dea6ee3fa4f38b66a197cfc22',
  // nutritionix_APPID:'dbc6a9f8',
  // nutritionix_APPKEY:'5c739e854323f22d810e449eddc16ae1',
  nutritionix_TOKEN: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NTU5ODkxLCJpYXQiOjE1NTcxMjMxOTQsImV4cCI6MTU1OTgwMTU5NH0.2m3CcW503GKxIpLhPbjz512wqmLCk75o8xujKUj3_m8',
  tracking_days: 0,
  universal_analytics_key: 'UA-150741450-1',
  domain_name: 'dev.fantasticlife.online',
  tawkToApiKey : '27135db8425de058acff481ddd68d163d75c790a'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI. http://54.165.66.119:8080

