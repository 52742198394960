import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebApiService } from '../service/web-api.service';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomValidatorService } from '../service/custom-validator.service';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { CustomValidators } from 'ng2-validation';
import { UserAuthService } from '../service/user/user-auth.service';
import { SocialLoginService } from '../service/user/social-login-service';
import { LanguageTransalte } from '../service/language-translate.service';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})



export class LoginComponent implements OnInit, OnDestroy {
  button_clicked;
  loginForm;
  password_type;
  constructor(public fb: FormBuilder,
    public _customValidators: CustomValidatorService,
    public _toaster: ToasterService,
    public _api: WebApiService,
    public _user: UserAuthService,
    public _socialLogin: SocialLoginService,
    public _router: Router,
    public _translate: LanguageTransalte) {
  }
  login() {
    let user;
    this.button_clicked = true;
    let form_data = this.loginForm.value;
    if (this.loginForm.valid) {
      let data = { email: form_data['email'], password: (form_data['password']) };
      this._customValidators.setUserData(data);
      this._api.checkLogin(data)
        .subscribe(data => {
          console.log("data--", data)
          if (data['code'] === 200) {
            this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.LoginSuccess'));
            user = data;
            let userId;
            if(localStorage.getItem('unsubscribe')) {
              userId = localStorage.getItem('unsubscribe');
            }
            if (user.isProfileUpdateRequired) {
              user.isPlanCreated = false
            }
            if (user && (!user.gender || user.isProfileUpdateRequired)) {
              this._router.navigate(['/profile-info']);
            } else if (user && (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired))) {
              this._router.navigate(['/started']);
            } else if (!user.isTargetSet) {
              this._router.navigate(['/target']);
            } else if (!user.isSubscriptionActive) {
              this._router.navigate(['/subscription-plan']);
            } else if (user.isSubscriptionActive) {
              if(userId) {
                this._router.navigate(['/unsubscribe/'+userId]);  
              } else {
                this._router.navigate(['/dashboard']);
              }
            }
            this._user.setUser(data);
          } else if (data['code'] === 404) {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.EmailPasswordIncorrect'));
          } else if (data['message']) {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), data['message']);
          }
        }, error => {
          console.log("error", error, error['status'] == 0)
          this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.EmailPasswordIncorrect'));
        });
    }
  }
  ngOnInit() {
    if (!localStorage.getItem('lang')) {
      this._router.navigate(['language'])
    }
    this.password_type = 'password'
    this.button_clicked = false;
    this.loginForm = this.fb.group({
      email: [null, Validators.compose([Validators.required, CustomValidators.email])],
      password: [null, Validators.compose([Validators.required])]
    });

    $(".fake-autofill-fields").show();
    // some DOM manipulation/ajax here
    window.setTimeout(function () {
      $(".fake-autofill-fields").hide();
    }, 1);
  }
  changefooter() {
    console.log("helw")
    $('.app-fixed').css({
      position: 'absolute',
      bottom: 0
    })
  }
  changefooter1() {
    console.log("helw1")
    $('.app-fixed').css({
      position: 'fixed',
      bottom: 0
    })
  }

  ngOnDestroy() {
    this._customValidators.setUserData(null);
  }
  Goto(link) {
    this._router.navigate([link]);
  }
}