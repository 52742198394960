import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidatorService } from '../service/custom-validator.service';
import { CustomValidators } from 'ng2-validation';
import { SocialLoginService } from '../service/user/social-login-service';
import { WebApiService } from '../service/web-api.service';
import { UserAuthService } from '../service/user/user-auth.service';
import { LanguageTransalte } from '../service/language-translate.service';
declare var $: any;
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  password_type;
  signupForm: FormGroup;
  hide: any = true;
  button_clicked;
  flag = 0;
  constructor(public fb: FormBuilder,
    public _customValidators: CustomValidatorService,
    public _toaster: ToasterService,
    public _router: Router,
    public zone: NgZone,
    public _routes: ActivatedRoute,
    public _socialLogin: SocialLoginService,
    public _api: WebApiService,
    public _user: UserAuthService,
    public _translate: LanguageTransalte) {
  }

  changefooter() {
    $('.app-fixed').css({
      position: 'absolute',
      bottom: 0
    })
  }
  changefooter1() {
    $('.app-fixed').css({
      position: 'fixed',
      bottom: 0
    })
  }

  signup() {
    this.button_clicked = true;
    let form_data = this.signupForm.value;
    form_data.differenceOfUTC = this._user.GetTimeZoneOffset()
    if (this.signupForm.valid) {
      console.log("form_data---", form_data)
      this._api.signup(form_data)
        .subscribe(data => {
          if (data['code'] === 200) {
            this._user.setUser(data);
            this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.SignupSuccess'));
            this._router.navigate(['/profile-info']);
          } else if (data['code'] === 406) {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.AlreadyExists'));
          } else if (data['message']) {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), data['message']);
          }
        }, error => {
          this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.InternalServerError'));
        });
    }
  }
  ngOnInit() {
    this.password_type = 'password'
    this.button_clicked = false;
    this.signupForm = this.fb.group({
      fName: [null, Validators.compose([Validators.required, Validators.maxLength(50), this._customValidators.NameRequiredValidation])],
      lName: [null, Validators.compose([Validators.required, Validators.maxLength(50), this._customValidators.NameRequiredValidation])],
      email: [null, Validators.compose([Validators.required, CustomValidators.email])],
      password: [null, Validators.compose([Validators.required, Validators.minLength(6),
      Validators.maxLength(15), Validators.pattern(this._customValidators.password_pattern)])]
    });
  }
  Goto(link) {
    this._router.navigate([link]);
  }
}