import { Injectable } from '@angular/core';

@Injectable()

export class CustomValidatorService {
    public password_pattern = '(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@#^$&!%*])[A-Za-z0-9@#^$&!%*]*';
    set_user_data;
    left_img;
    right_img;
    front_img;
    back_img;
    public  NameRequiredValidation(input): { [key: string]: any } {
        if (!(input.value && input.value.trim().length > 0)) {
            return { 'name_required': true };
        }
    }
     
    public setUserData(data){
        console.log("data---",data)
        return this.set_user_data =data;
    }    
    public getUserData(){
        return this.set_user_data;
    }
    public setImageData(data, type) {
        console.log("data---",data,type)
        if (type === 'left_img') {
            this.left_img = data;
        } else  if (type === 'right_img'){
            this.right_img = data;
        } else  if (type === 'front_img'){
            this.front_img = data;
        } else  if (type === 'back_img'){
            this.back_img = data;
        }
    }
    public getImageData(type) {
        if (type === 'left_img') {
            return this.left_img;
        } else  if (type === 'right_img'){
            return this.right_img;
        } else  if (type === 'front_img'){
            console.log(" this.front_img;--", this.front_img)
            return this.front_img;
        } else  if (type === 'back_img'){
            return this.back_img;
        }
    }
}
