import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-introductry-page',
  templateUrl: './introductry-page.component.html',
  styleUrls: ['./introductry-page.component.scss']
})
export class IntroductryPageComponent implements OnInit {
  constructor(public _router: Router) { }

  ngOnInit() {
    if(localStorage.getItem('answers')) {
      this._router.navigate(['/questionnaire']);
    }
  }
}
