import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  step = 1;
  constructor(public _router: Router) { }
  GetStarted() {
    localStorage.setItem('appLaunched', 'true');
    this._router.navigate(['/login']);
  }

}