import { Component, OnInit } from '@angular/core';
import { Languages, UserAuthService } from '../service/user/user-auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { WebApiService } from '../service/web-api.service';
import { environment } from 'src/environments/environment';
declare var $: any;
declare var ga: any;
// import ua from 'universal-analytics'
// declare let ua: Function;

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  languages = Languages;
  languages_to_show;
  language_to_search;
  selected_language = 'en';
  constructor(public _router: Router,
    public translate: TranslateService,
    public _toaster: ToasterService,
    public _api: WebApiService,
    public _user: UserAuthService) { }
  ngOnInit() {
    this.languages_to_show = this.languages;
    // this.selected_language = this.languages[0]['key'];
    if (localStorage.getItem('lang')) {
      this.selected_language = localStorage.getItem('lang');
    }
    $(document).ready(function () {
      $('.owl-carousel').owlCarousel({
        center: false,
        items: 2,
        loop: false,
        margin: 10,
        responsive: {
          600: {
            items: 3
          }
        }
      });
      // $('.language-type').click(function () {
      //   if ($(this).hasClass('current')) {
      //     $(this).removeClass('current');
      //   } else {
      //     $('.language-type.current').removeClass('current');
      //     $(this).addClass('current');
      //   }
      // });
      // $('.language-list-div ul li a').click(function () {
      //   if ($(this).hasClass('active')) {
      //     $(this).removeClass(' active');
      //   } else {
      //     $('.language-list-div ul li a.active').removeClass('active');
      //     $(this).addClass('active');
      //   }
      // });
    });
  }
  FilterLanguage(text) {
    this.languages_to_show = this.languages.filter(language => language.name.toLowerCase().indexOf(text.toLowerCase()) !== -1)
  }
  ClearInputBox() {
    this.language_to_search = ''
    this.languages_to_show = this.languages;
  }

  ChooseLanguage() {
    
    this.translate.use(this.selected_language);
    console.log(this.selected_language);
    localStorage.setItem('lang', this.selected_language)
    if (this._user.user) {
      this._api.setUserLanguage({ language: this.selected_language })
        .subscribe(data => {
          console.log(data);
        }, err => {
          console.log(err);
        })
        this._toaster.pop('success', 'Success', 'Language selected successfully')
      this._router.navigate(['/profile']);
    } else {
      this._toaster.pop('success', 'Success', 'Language selected successfully')
      this._router.navigate(['/onboarding']);
    }
  }
  expression() {
    console.log('functrion called', ga)
    ga('create', environment.universal_analytics_key, 'auto');
    ga('send', 'pageview');
    ga('send', 'event', 'language', 'help');
  }
}
