import {Component, Input, OnDestroy, ViewEncapsulation, ChangeDetectorRef, Pipe} from '@angular/core';
import {Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import { LoaderService } from '../loader/loader.service';


@Component({
    selector: 'app-spinner',
    template: `
        <div class="preloader" *ngIf="isSpinnerVisible">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {
    public isSpinnerVisible = false;
    @Input() public backgroundColor = 'rgba(0, 115, 170, 0.69)';

    constructor(private cdRef: ChangeDetectorRef, private router: Router, public loader: LoaderService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.isSpinnerVisible = true;
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isSpinnerVisible = false;
            }
        }, () => {
            this.isSpinnerVisible = false;
        });
        this.loader.spinner$.subscribe( value =>  {
            this.isSpinnerVisible = value;
            this.cdRef.detectChanges();
        });
    }



    ngOnDestroy(): void {
        this.isSpinnerVisible = false;
    }
}
