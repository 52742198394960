import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angular-6-social-login';
import { ToasterService } from 'angular2-toaster';
import { WebApiService } from '../web-api.service';
import { UserAuthService } from './user-auth.service';


@Injectable()
export class SocialLoginService {
  constructor(public _router: Router,
    public _routes: ActivatedRoute,
    public _toaster: ToasterService,
    public _api: WebApiService,
    public _user: UserAuthService,
    public socialAuthService: AuthService) {
  }

  socialSignIn(socialPlatform) {
    let socialPlatformProvider;
    if (socialPlatform === 'facebook') {
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    } else if (socialPlatform === 'google') {
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    }
    this.socialLoginAuthService(socialPlatformProvider);

  }
  socialLoginAuthService(socialPlatformProvider) {
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        console.log(userData)
        const user_data = {
          socialType: userData['provider'],
          email: userData['email'],
          socialId: userData.id,
          fName: !!userData['first_name'] ? userData['first_name'] : "",
          lName: !!userData['last_name'] ? userData['last_name'] : ""
        };
        console.log('user_data--', user_data);
        if (user_data['socialId']) {
          this.socialLoginUsingToken(user_data);
        }
      }).catch(function (err) {
        alert("Popup blocked! Please check your settings");
      });
  }

  socialLoginUsingToken(user_data) {
    let user;
    user_data.differenceOfUTC = this._user.GetTimeZoneOffset()
    this._api.socialLogin(user_data)
      .subscribe(data => {
        console.log(data);
        if (data['code'] === 200) {
          this._toaster.pop('success', 'Success', 'Login Successfully ');
          user = data;
          if (user.isProfileUpdateRequired) {
            user.isPlanCreated = false
          }
          if (user && (!user.gender || user.isProfileUpdateRequired)) {
            this._router.navigate(['/profile-info']);
          } else if (user && (!user.hasUserFilledAnswersAtleastOnce || (user.hasUserFilledAnswersAtleastOnce && user.isAnswersRequired))) {
            this._router.navigate(['/started']);
          } else if (!user.isTargetSet) {
            this._router.navigate(['/target']);
          } else if (!user.isSubscriptionActive) {
            this._router.navigate(['/subscription-plan']);
          } else if (user.isSubscriptionActive) {
            this._router.navigate(['/dashboard']);
          }
          this._user.setUser(data);
        } else if (data['message']) {
          this._toaster.pop('error', 'Error', data['message']);
        }
      }, error2 => {
        console.log(error2);
      });
  }
}