import { Directive, NgZone, HostListener, Input } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { ImagesService } from '../service/images.service';
import { LoaderService } from '../service/loader/loader.service';
declare var ImageCompressor: any;
import * as _ from 'underscore';
const compressor = new ImageCompressor();
@Directive({
    selector: '[appImgCompressor]'
})
export class ImgCompressorDirective {
    @Input() fileType: any;
    constructor(private zone: NgZone,
        public _toaster: ToasterService,
        public _loader: LoaderService,
        public _imageService: ImagesService) {
    }
    @HostListener('change', ['$event']) onchange(event) {
        console.log(event.target.files)
        var allowed_extensions_img = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
        if (event.srcElement.attributes.name.value === 'food') {
            if (event.target.files.length + this._imageService.nutrition_images.length > 30) {
                this._toaster.pop('error', 'Error', 'You can upload at most 30 images for the food activity')
                return;
            }
            if (event.target.files.length > 10) {
                this._toaster.pop('warning', 'Warning', 'You can upload 10 images at one time')
                return;
            }
        }
        const files: FileList = event.target.files;
        console.log(files)
        if (files.length > 0) {
            this._loader.show();
            this.zone.runOutsideAngular(() => {
                const promises: Promise<Blob>[] = [];
                for (let i = 0; i < files.length; ++i) {
                    const file = files[i];
                    promises.push(compressor.compress(file, { quality: .1 }));
                }
                Promise.all(promises).then(_files => {
                    _files.forEach(file => {
                        console.log(file, file['name'])
                        console.log(this.isInArray(allowed_extensions_img, file['name'].split('.').pop()))
                        if (this.isInArray(allowed_extensions_img, file['name'].split('.').pop())) {
                            var reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onloadend = () => {
                                this._imageService.nutrition_images = [...[{ imageBase64: reader['result'].toString().split(',')[1] }], ...this._imageService.nutrition_images]
                            }
                        } else {
                            this._toaster.pop('error', 'Error', 'Image must be PNG and JPG formats');
                        }
                    })
                    event.target.value = "";
                    this._loader.hide();
                }).catch(err => {
                    console.log(err);
                    this._loader.hide();
                    this._toaster.pop('error', 'Error', 'Image must be PNG and JPG formats');
                })
            });
        }
    }
    isInArray(array, word) {
        return array.indexOf(word.toLowerCase()) > -1;
    }
}