import { Component, OnInit } from '@angular/core';
declare var $: any;
import { UserAuthService } from '../service/user/user-auth.service';
import { WebApiService } from '../service/web-api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-body-type',
  templateUrl: './body-type.component.html',
  styleUrls: ['./body-type.component.scss']
})
export class BodyTypeComponent implements OnInit {
  questionnaire_rating = 0;
  constructor(public _user: UserAuthService,
    public _router: Router,
    public _api: WebApiService) { }

  ngOnInit() {
  }
  SaveQuestionnaireExperience() {
    this._api.saveQuestionnaireExperience(this.questionnaire_rating)
      .subscribe(data => console.log(data), err => console.log(err))
    this._router.navigate(['/OurGoal'])
  }
}