import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpService } from '../service/http.service';
import { ToasterService } from 'angular2-toaster';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomValidatorService } from '../service/custom-validator.service';
import { WebApiService } from '../service/web-api.service';
import { UserAuthService } from '../service/user/user-auth.service';
import { LanguageTransalte } from '../service/language-translate.service';

declare var $: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  hide: any = true;
  resetPasswordLinkToken;
  button_clicked;
  password_type;
  password_type1;
  focusIn = false;
  constructor(public fb: FormBuilder,
    public _toaster: ToasterService,
    public _router: Router,
    public activatedRoute: ActivatedRoute,
    public _user: UserAuthService,
    public _customValidators: CustomValidatorService,
    public _api: WebApiService,
    public _translate: LanguageTransalte) {
  }

  ngOnInit() {
    this.button_clicked = false;
    this.password_type = 'password'
    this.password_type1 = 'password'
    this.resetPasswordLinkToken = this.activatedRoute.snapshot.queryParams.token;
    // if (!this.resetPasswordLinkToken) {
    //   this._router.navigate(['/']);
    // } else {
    //   this._user.resetUser();
    // }
    // if (this.resetPasswordLinkToken) {
    //   this._api.validateToken({ pwd_token: this.resetPasswordLinkToken })
    //     .subscribe(data => {
    //       if (data['status'] !== 200) {
    //         this._toaster.pop('error', 'Error', 'Reset password link expired');
    //         this._router.navigate(['/']);
    //       }
    //     }, error2 => {
    //       this._router.navigate(['']);
    //     });
    // }

    this.resetPasswordForm = this.fb.group({
      password: [null, Validators.compose([Validators.required, Validators.minLength(6),
      Validators.maxLength(15), Validators.pattern(this._customValidators.password_pattern)])],
      new_password: [null, Validators.compose([Validators.required, Validators.minLength(6),
      Validators.maxLength(15), Validators.pattern(this._customValidators.password_pattern)])]
    });
  }

  changeCss() {
    console.log("helw");
    this.focusIn = true;
  }

  // reset passowrd
  resetPassword() {
    this.button_clicked = true;
    if (this.resetPasswordForm.valid) {
      const data_to_send = {
        password: this.resetPasswordForm.value['password'],
        token: this.resetPasswordLinkToken
      };
      if (this.resetPasswordForm.value['password'] &&
        this.resetPasswordForm.value['password'] != this.resetPasswordForm.value['new_password']) {
        this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.NewPasswordMismatch'));
        return;
      }
      this._api.resetPassword(data_to_send)
        .subscribe(data => {
          if (data['code'] === 200) {
            this._toaster.pop('success', this._translate.getTranslation('ToasterMessages.Success'), this._translate.getTranslation('ToasterMessages.PasswordUpdatedSuccess'));
            this._router.navigate(['/login']);
          } else if (data['code'] === 400) {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.LinkExpired'));
          } else if (data['msg']) {
            this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), data['msg']);
          }
          this.resetPasswordForm.reset();
        }, error => {
          this.resetPasswordForm.reset();
          this._toaster.pop('error', this._translate.getTranslation('ToasterMessages.Error'), this._translate.getTranslation('ToasterMessages.InternalServerError'));
        });
    }
  }
  Goto(link) {
    this._router.navigate([link]);
  }
}
