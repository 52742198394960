import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
@Injectable()
export class WebApiService {
    constructor(public _http: HttpService) { }
    env = environment
    checkLogin(data) {
        return this._http.post('/login', data);
    }
    socialLogin(data) {
        return this._http.post('/socialLogin', data);
    }
    logout() {
        return this._http.post('/logoutUser', {});
    }
    forgotPassword(data) {
        return this._http.post('/forgotPassword', data);
    }
    validateToken(data) {
        return this._http.post('/api/auth/validateToken', data);
    }
    resetPassword(data) {
        return this._http.post('/setNewPassword', data)
    }
    signup(data) {
        return this._http.post('/signup', data);
    }
    GetQuestions() {
        return this._http.get('/getQuestionnaire');
    }
    SaveQuestions(data) {
        return this._http.post('/saveQuestionnaireAnswers', data);
    }
    introductory(data) {
        return this._http.post('/user/acknowledgement', data);
    }
    getUserDetails(data) {
        return this._http.get('/getUserDetails', data);
    }
    getCurrentBodyWeightAndDifference(data) {
        return this._http.post('/getCurrentBodyWeightAndDifference', data);
    }
    setBodyWeight(data) {
        return this._http.post('/setBodyWeight', { ...data, ...{ "weightTime": moment().unix() } });
    }
    getWeightHistory() {
        return this._http.get('/getWeightHistory');
    }
    UpdateUserProfile(data) {
        console.log("data=", data);
        return this._http.post('/UpdateUserProfile', data);
    }
    addBodyShots(data) {
        return this._http.post('/saveBodyShot', data);
    }
    bodyShotsList() {
        return this._http.get('/fetchBodyShot');
    }
    bodyShotsDetails(id) {
        return this._http.get(`/fetchSingleBodyShot/${id}`);
    }
    deleteBodyShot(data) {
        return this._http.post('/deleteBodyShot', data);
    }
    addUpdateProfilePhoto(data) {
        return this._http.post('/addUpdateProfilePhoto', data);
    }

    // Exercise activity process
    getExerciseList() {
        return this._http.get('/getExerciseList');
    }
    getExercise(exerciseId) {
        return this._http.get('/getExercise/' + exerciseId);
    }
    saveExerciseActivity(data) {
        return this._http.post('/saveExerciseActivity', data);
    }
    getExerciseActivity(activityId) {
        return this._http.get('/getExerciseActivity/' + activityId);
    }
    updateExerciseActivity(data, activityId) {
        return this._http.post('/updateExerciseActivity/' + activityId, data);
    }
    deleteExerciseActivity(activityId) {
        return this._http.delete('/deleteExerciseActivity/' + activityId);
    }

    // Sleep activity process
    saveSleepActivity(data) {
        return this._http.post('/saveSleepActivity', data);
    }
    getSleepActivityDetails(activityId) {
        return this._http.get('/getSleepActivityDetails/' + activityId);
    }
    updateSleepActivity(data, activityId) {
        return this._http.post('/updateSleepActivity/' + activityId, data);
    }
    deleteSleepActivity(activityId) {
        return this._http.delete('/deleteSleepActivity/' + activityId);
    }

    // Meditation activity process
    getMeditationList() {
        return this._http.get('/getMindList');
    }
    getMindDetails(mindId) {
        return this._http.get('/getMindDetails/' + mindId);
    }
    saveMeditationActivity(data) {
        return this._http.post('/saveMindActivity', data);
    }
    getMeditationActivity(activityId) {
        return this._http.get('/getMindActivityDetails/' + activityId);
    }
    updateMeditationActivity(activityId, data) {
        return this._http.post('/updateMindActivity/' + activityId, data);
    }
    deleteMeditationActivity(activityId) {
        return this._http.get('/deleteMindActivity/' + activityId);
    }

    // Functions to get the list
    getSleepActivityListOfInterval(data) {
        return this._http.post('/getSleepActivityListOfInterval', data);
    }
    getExerciseActivityListOfInterval(data) {
        return this._http.post('/getExerciseActivityListOfInterval', data);
    }
    getMindActivityListOfInterval(data) {
        return this._http.post('/getMindActivityListOfInterval', data);
    }


    getSleepActivityList() {
        return this._http.get('/getSleepActivityList');
    }
    // functions to call Average APIs
    getAvgWeeklySleepActivityList(data) {
        return this._http.post('/getAvgWeeklySleepActivityList', data);
    }
    getAvgMonthlySleepActivityList(data) {
        return this._http.post('/getAvgMonthlySleepActivityList', data);
    }
    getAvgYearlySleepActivityList(data) {
        return this._http.post('/getAvgYearlySleepActivityList', data);
    }
    getAvgWeeklyMindActivityList(data) {
        return this._http.post('/getAvgWeeklyMindActivityList', data);
    }
    getAvgMonthlyMindActivityList(data) {
        return this._http.post('/getAvgMonthlyMindActivityList', data);
    }
    getAvgYearlyMindActivityList(data) {
        return this._http.post('/getAvgYearlyMindActivityList', data);
    }
    getAvgWeeklyExercise(data) {
        return this._http.post('/getAvgWeeklyExercise', data);
    }
    getAvgMonthlyExercise(data) {
        return this._http.post('/getAvgMonthlyExercise', data);
    }
    getAvgYearlyExercise(data) {
        return this._http.post('/getAvgYearlyExercise', data);
    }

    // nutrition activities
    saveFoodActivity(data) {
        return this._http.post('/saveFoodActivity', data);
    }
    getFoodActivity(activityId) {
        return this._http.get('/getFoodActivity/' + activityId);
    }
    deleteFoodActivity(activityId) {
        return this._http.delete('/deleteFoodActivity/' + activityId);
    }
    updateFoodActivity(data, activityId) {
        return this._http.post('/updateFoodActivity/' + activityId, data);
    }
    getFoodActivityListOfInterval(data) {
        return this._http.post('/getFoodActivityListOfInterval', data);
    }
    getFoodItemsListWithNutritionalInfo() {
        return this._http.get('/getFoodItemsListWithNutritionalInfo');
    }
    getHydrationValueInOZ(data) {
        return this._http.post('/getHydrationValueInOZ', data);
    }
    setHydrationValueInOZ(data) {
        return this._http.post('/setHydrationValueInOZ', data);
    }
    createCustomerInStripe(data) {
        return this._http.post('/createCustomerInStripe', data);
    }
    getCreditCardDetail() {
        return this._http.get('/getCreditCardDetail');
    }
    updateCreditCard(data) {
        return this._http.post('/updateCreditCard', data);
    }
    getFoodsToEnjoy() {
        return this._http.get('/getFoodsToEnjoy');
    }
    getFoodsToAvoid() {
        return this._http.get('/getFoodsToAvoid');
    }
    getFoodPlanFeatures() {
        return this._http.get('/getFoodPlanFeatures');
    }
    getNutritionSupplements() {
        return this._http.get('/getNutritionSupplements');
    }
    getDailyPlanDetails() {
        return this._http.get('/getDailyPlanDetails');
    }
    getTotalPoints() {
        return this._http.get('/getTotalPoints');
    }
    getExercisePlan() {
        return this._http.get('/getExercisePlan');
    }
    getAllActivitiesList(data) {
        return this._http.post('/getAllActivitiesList', data);
    }
    getWeeklyTotalPoints(data) {
        return this._http.post('/getWeeklyTotalPoints', data);
    }
    getMonthlyTotalPoints(data) {
        return this._http.post('/getMonthlyTotalPoints', data);
    }
    getYearlyTotalPoints(data) {
        return this._http.post('/getYearlyTotalPoints', data);
    }

    // functions for user journey
    canICreatePlan() {
        return this._http.get('/canICreatePlan');
    }
    savePlanInfo() {
        return this._http.post('/savePlanInfo', {});
    }
    getRecommendedNutrition(data) {
        return this._http.post('/getRecommendedNutrition', data);
    }
    getTotalCompliance(data) {
        return this._http.post('/getTotalCompliance', data);
    }
    downloadPlanPDF() {
        return this._http.post('/downloadPlanPDF', {});
    }
    getPaymentDueDate() {
        return this._http.get('/getPaymentDueDate');
    }
    startMonthlySubscription(data) {
        return this._http.post('/startMonthlySubscription', data);
    }
    startAnualSubscription(data) {
        return this._http.post('/startAnualSubscription', data);
    }
    sendPlanPDFmail() {
        return this._http.post('/sendPlanPDFmail', {});
    }
    isStartingPaymentRequired() {
        return this._http.get('/isStartingPaymentRequired');
    }
    saveBrowserToken(data) {
        return this._http.post('/saveBrowserToken', data);
    }
    getNotificationStatusList() {
        return this._http.get('/getNotificationStatusList');
    }
    setNotificationResponse(data) {
        return this._http.post('/setNotificationResponse', data);
    }
    setUserLanguage(data) {
        return this._http.post('/setUserLanguage', data);
    }
    saveFeelAnswer(data) {
        return this._http.post('/saveFeelAnswer', data);
    }
    getFoodSearch(query) {
        return this._http.get(`/getFoodSearch?APPID=${this.env.nutritionix_APPID}&APPKEY=${this.env.nutritionix_APPKEY}&TOKEN=${this.env.nutritionix_TOKEN}&query=` + query)
    }
    getFoodRecipe(query) {
        return this._http.get(`/getFoodRecipe?APPID=${this.env.nutritionix_APPID}&APPKEY=${this.env.nutritionix_APPKEY}&query=` + query)
    }
    getFavoriteFoodItemList() {
        return this._http.post('/getFavoriteFoodItemList', {});
    }
    getMultipleFoodRecipe(query) {
        return this._http.post(`/getMultipleFoodRecipe?APPID=${this.env.nutritionix_APPID}&APPKEY=${this.env.nutritionix_APPKEY}&TOKEN=${this.env.nutritionix_TOKEN}`, query);
    }
    updateFavoriteFoodItem(favorite_item_id, data) {
        return this._http.post('/updateFavoriteFoodItem/' + favorite_item_id, data);
    }
    saveFavoriteFoodItem(data) {
        return this._http.post('/saveFavoriteFoodItem', data);
    }
    deleteFavoriteFoodItem(favorite_item_id) {
        return this._http.delete('/deleteFavoriteFoodItem/' + favorite_item_id);
    }
    getFavoriteFoodItem(favorite_item_id) {
        return this._http.get('/getFavoriteFoodItem/' + favorite_item_id);
    }
    getMultipleFavoriteFoodItem(data) {
        return this._http.post('/getMultipleFavoriteFoodItem', data)
    }
    saveFavoriteFoodItemByName(data) {
        return this._http.post(`/saveFavoriteFoodItemByName?APPID=${this.env.nutritionix_APPID}&APPKEY=${this.env.nutritionix_APPKEY}`, data)
    }
    getAdditionalGoal() {
        return this._http.get('/getAdditionalGoal');
    }
    setUserGoal(data) {
        return this._http.post('/setUserGoal/' + data, {});
    }
    getInitialValueOfTargetScreen() {
        return this._http.get('/getInitialValueOfTargetScreen');
    }
    saveQuestionnaireExperience(rating) {
        return this._http.get('/saveQuestionnaireExperience?rating=' + rating);
    }
    setUserTarget(data) {
        return this._http.post('/setUserTarget', data);
    }
    getPromoCodeDetail(data) {
        return this._http.post('/getPromoCodeDetail', data);
    }
    getWeightDetails() {
        return this._http.get('/getWeightDetails');
    }
    getWeeklyWeight(data) {
        return this._http.post('/getWeeklyWeight', data);
    }
    getMonthlyWeight(data) {
        return this._http.post('/getMonthlyWeight', data);
    }
    getRecommendedMeal() {
        return this._http.post('/getRecommendedMeal', { differenceOfUTC: new Date().getTimezoneOffset() * 60, localTime: moment().unix() });
    }
    isLogWeightQuestionRequired() {
        return this._http.post('/isLogWeightQuestionRequired', { differenceOfUTC: new Date().getTimezoneOffset() * 60, localTime: moment().unix() });
    }
    getSurveyQuestions() {
        return this._http.get('/getSurveyQuestions');
    }
    saveSurveyQuestionnaireAnswer(data) {
        return this._http.post('/saveSurveyQuestionnaireAnswer', data);
    }
    saveSurveyRating(data) {
        return this._http.post('/saveSurveyRating', data);
    }
    sendSurveyTextMessage(data) {
        return this._http.post('/sendSurveyTextMessage', data);
    }
    receiveBasicPlan(data) {
        return this._http.post('/receiveBasicPlan', data);
    }
    resetCompliance() {
        return this._http.post('/resetCompliance', {});
    }
    createOneTimeCharge(data) {
        return this._http.post('/createOneTimeCharge', data);
    }
    generateAnnual100PerCodes(data) {
        return this._http.post('/generateAnnual100PerCodes', data);
    }
    sendPromoCodeMail(data) {
        return this._http.post('/sendPromoCodeMail', data);
    }
    isAdmin(data) {
        return this._http.get('/isAdmin');
    }
    deleteUser(data) {
        console.log(data);
        return this._http.post('/deleteUser', data);
    }
    setLocalTime() {
        return this._http.post('/setLocalTime', { differenceOfUTC : new Date().getTimezoneOffset() * 60, localTime : moment().unix() })
    }
    getAllPromoCodeDetail() {
        return this._http.get('/getAllPromoCodeDetail');
    }
    generatePromoCode(data) {
        return this._http.post('/generatePromoCode', data);
    }
    unSubscribeNewsLetter() {
        return this._http.get('/unSubscribeNewsLetter');
    }
    findUser(data) {
        return this._http.post('/findUser', data);
    }
}